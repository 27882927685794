import React from 'react';
import { Drawer } from 'antd';
import { useScreenData } from 'hooks/screen';

export default function ResponsiveDrawer({ children, width, ...props }) {
  const screen = useScreenData();

  const drawerWidth = screen.width > 500 ? 500 : screen.width - 50;

  return (
    <Drawer width={width || drawerWidth} destroyOnClose {...props}>
      {children}
    </Drawer>
  );
}
