import React from 'react';
import { CirclePicker } from 'react-color';

export default function ColorInput(props) {
  const { onChange, value, withoutHash } = props;

  const currentColor = withoutHash ? `#${value || ''}` : value;

  function handleChange(color) {
    if (typeof onChange === 'function') {
      onChange(withoutHash ? color.hex.replace('#', '') : color.hex);
    }
  }

  return <CirclePicker color={currentColor} onChange={handleChange} />;
}
