import { useMutation, useQuery } from '@apollo/react-hooks';
import { buyers, insert_buyers, update_buyers } from './queries.graphql';

export function useBuyers() {
  return useQuery(buyers);
}

export function useInsertBuyers() {
  const [mutate] = useMutation(insert_buyers);

  return objects =>
    mutate({
      awaitRefetchQueries: true,
      refetchQueries: [{ query: buyers }],
      variables: {
        objects
      }
    });
}

export function useUpdateBuyers(where) {
  const [mutate] = useMutation(update_buyers);
  return data =>
    mutate({
      variables: {
        where,
        _set: data
      }
    });
}
