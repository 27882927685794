import React from 'react';
import { Affix } from 'antd';
import classes from './index.module.css';

export default function AffixButtons({ children }) {
  return (
    <Affix offsetBottom={0}>
      <div className={classes.wrapper}>{children}</div>
    </Affix>
  );
}
