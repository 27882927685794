import React, { useCallback } from 'react';
import get from 'lodash/get';
import { useApolloClient } from '@apollo/react-hooks';
import query from 'queries/brand/search-brands.graphql';
import SelectInput from './AsyncSearchSelectInput';

export default function BrandInput(props) {
  let { mode, value, ...rest } = props;

  let apollo = useApolloClient();

  let searchByKeyword = useCallback(
    async name => {
      let result = await apollo.query({
        query,
        variables: {
          limit: 10,
          where: {
            name: {
              _ilike: `%${name}%`
            }
          }
        }
      });

      return get(result, 'data.result', []).map(brand => ({
        label: brand.name,
        value: brand.id
      }));
    },
    [apollo]
  );

  let searchByValue = useCallback(
    async ids => {
      let result = await apollo.query({
        query,
        variables: {
          where: {
            id: {
              _in: ids
            }
          }
        }
      });

      return get(result, 'data.result', []).map(brand => ({
        label: brand.name,
        value: brand.id
      }));
    },
    [apollo]
  );

  return (
    <SelectInput
      mode={mode}
      searchByValue={searchByValue}
      searchByKeyword={searchByKeyword}
      value={value}
      {...rest}
    />
  );
}

BrandInput.defaultProps = {
  allowClear: true
};
