import React from 'react';
import get from 'lodash/get';
import { useQuery } from '@apollo/react-hooks';
import query from 'queries/cart/product-info.graphql';

export default function ProductInfo({ id, extra, qty }) {
  let { data, loading } = useQuery(query, {
    variables: {
      id
    }
  });

  return (
    <>
      {loading ? '...' : get(data, 'product.product_view.name', '-')}
      <br />
      <small>
        SKU:&nbsp;{loading ? '...' : get(data, 'product.product_view.sku', '-')}
        {get(data, 'product.product_view.unit', '-') && (
          <>
            &nbsp;&nbsp;&nbsp;&nbsp; Pack Size:&nbsp;
            {loading ? '...' : get(data, 'product.product_view.unit', '-')}
          </>
        )}
        &nbsp;&nbsp;&nbsp;&nbsp;
        {qty ? `QTY: ${qty}` : ''}
      </small>
    </>
  );
}
