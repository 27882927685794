import { useCallback, useRef } from 'react';

export default function useDebounceCallback(
  callback,
  deps = [],
  wait = 500,
  immediate
) {
  const timeout = useRef();

  return useCallback(
    function() {
      const context = this;
      const args = arguments;

      const later = function() {
        timeout.current = null;
        if (!immediate) {
          callback.apply(context, args);
        }
      };

      const callNow = immediate && !timeout.current;

      clearTimeout(timeout.current);

      timeout.current = setTimeout(later, wait);

      if (callNow) {
        callback.apply(context, args);
      }
    },
    [...deps, wait, immediate]
  );
}
