import { useQuery } from '@apollo/react-hooks';
import apollo from 'core/apollo';
import query from 'queries/supplier/suppliers.graphql';

export default function useSuppliers() {
  return useQuery(query, getSuppliersQueryOptions());
}

export function fetchSuppliers() {
  return apollo.query(getSuppliersQueryOptions());
}

export function getSuppliersQueryOptions() {
  return {
    query,
    fetchPolicy: 'cache-first'
  };
}
