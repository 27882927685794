import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { Col, Form, Row, Spin } from 'antd';
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import countries from 'country-state-city/dist/lib/country';
import TextInput from './TextInput';
import SelectInput from './SelectInput';
import AutoCompleteInput from './AutoCompleteInput';

let defaultMapCenter = {
  lat: -33.865143,
  lng: 151.2099
};

let mapOptions = {
  zoomControl: false,
  mapTypeControl: false,
  scaleControl: false,
  streetViewControl: false,
  rotateControl: false,
  fullscreenControl: false
};

let mapContainerStyle = {
  height: '208px',
  width: '100%'
};

export default function FullAddressInput({ label, onChange, value }) {
  let { isLoaded } = useLoadScript({
    googleMapsApiKey: 'AIzaSyDHnqUJEMGsGY_NqQluiQoKxnnwYL44hJo',
    libraries: ['places']
  });

  let [isReady, setIsReady] = useState(false);

  let mapRef = useRef();

  let lastAddressesRef = useRef([]);

  let data = value || {};

  let mapCenter =
    data.lat && data.lng
      ? {
          lat: data.lat,
          lng: data.lng
        }
      : defaultMapCenter;

  let coordinates =
    data.lat && data.lng
      ? {
          lat: data.lat,
          lng: data.lng
        }
      : undefined;

  let getInputProps = (name, defaultValue) => ({
    onChange: fieldValue => {
      let nextData = {
        ...data,
        [name]: fieldValue
      };

      if (name === 'country_code' && fieldValue !== data.country_code) {
        nextData.state = '';
      }

      onChange(nextData);
    },
    value: data[name] || defaultValue
  });

  let onLoadMaps = map => {
    mapRef.current = map;
    setIsReady(true);
  };

  let handleSearchAddress = keyword => {
    return new Promise((resolve, reject) => {
      let request = {
        query: keyword,
        fields: ['name', 'geometry', 'place_id', 'formatted_address']
      };

      let service = new window.google.maps.places.PlacesService(mapRef.current);

      service.findPlaceFromQuery(request, function(results, status) {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          lastAddressesRef.current = results;
          resolve(
            results.map(item => ({
              text: item.formatted_address,
              value: item.place_id
            }))
          );
        } else {
          resolve([]);
        }
      });
    });
  };

  let handleSelectAddress = async placeId => {
    let option = lastAddressesRef.current.find(
      item => item.place_id === placeId
    );

    if (option) {
      let request = {
        placeId,
        fields: [
          'name',
          'formatted_address',
          'address_components',
          'formatted_phone_number',
          'geometry'
        ]
      };

      let service = new window.google.maps.places.PlacesService(mapRef.current);

      service.getDetails(request, (place, status) => {
        if (status == window.google.maps.places.PlacesServiceStatus.OK) {
          let nextData = {
            ...data,
            lat: option.geometry.location.lat() || undefined,
            lng: option.geometry.location.lng() || undefined,
            address_1: place.formatted_address
          };
          place.address_components.forEach(item => {
            if (item.types.indexOf('country') >= 0) {
              nextData.country_code = item.short_name;
            }
            if (item.types.indexOf('administrative_area_level_1') >= 0) {
              nextData.state = item.long_name;
            }
            if (item.types.indexOf('locality') >= 0) {
              nextData.city = item.short_name;
            }
            if (item.types.indexOf('postal_code') >= 0) {
              nextData.postal_code = item.short_name;
            }
          });
          onChange(nextData);
        }
      });
    }
  };

  return (
    <Wrapper>
      <Row gutter={32}>
        <Col md={14}>
          <Form.Item label={label}>
            <AutoCompleteInput
              disabled={!isReady}
              {...getInputProps('address_1', '')}
              onSearch={handleSearchAddress}
              onSelect={handleSelectAddress}
              placeholder="Address"
            />
          </Form.Item>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Postal Code">
                <TextInput
                  {...getInputProps('postal_code', '')}
                  placeholder="ZIP/Post Code"
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item label="City">
                <TextInput {...getInputProps('city', '')} placeholder="City" />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item label="Country">
                <CountryInput {...getInputProps('country_code')} />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item label="State">
                <TextInput placeholder="State" {...getInputProps('state')} />
              </Form.Item>
            </Col>
          </Row>
        </Col>

        <Col md={10}>
          <MapWrapper>
            <Spin spinning={!isReady}>
              <div style={mapContainerStyle}>
                {isLoaded && (
                  <GoogleMap
                    center={mapCenter}
                    mapContainerStyle={mapContainerStyle}
                    onLoad={onLoadMaps}
                    options={mapOptions}
                    zoom={mapCenter ? 15 : 7}
                  >
                    {coordinates ? <Marker position={coordinates} /> : null}
                  </GoogleMap>
                )}
              </div>
            </Spin>
          </MapWrapper>
        </Col>
      </Row>
    </Wrapper>
  );
}

FullAddressInput.defaultProps = {
  label: 'Address'
};

export function CountryInput(props) {
  return (
    <SelectInput placeholder="Country" {...props}>
      {countries.map(country => (
        <SelectInput.Option key={country.sortname} value={country.sortname}>
          {country.name}
        </SelectInput.Option>
      ))}
    </SelectInput>
  );
}

let MapWrapper = styled('div')`
  background-color: #eee;
  border-radius: 4px;
  height: 208px;
  margin-top: 42px;
  overflow: hidden;
`;

let Wrapper = styled('div')`
  margin-bottom: 16px !important;

  .ant-form-item {
    margin-bottom: 8px !important;
  }
`;
