import React, { useState } from 'react';
import { Drawer } from 'antd';
import env from 'config/env';
import classes from './QuickAccessButton.module.css';
import Button from 'ui/Button';
import useDevice from 'hooks/useDevice';
import TransferSaleStock from 'components/transfer-sale-stock';

const POS_DOMAIN = env('POS_DOMAIN');

export default function QuickAccessButton() {
  const device = useDevice();
  const [visible, setVisible] = useState(false);
  const onClose = () => setVisible(false);
  const onOpen = () => setVisible(true);

  return (
    <>
      <Button
        className={classes.quickButton}
        onClick={onOpen}
        type="primary"
        icon="plus"
      >
        Create
      </Button>

      <Drawer
        title="Quick Access"
        onClose={onClose}
        visible={visible}
        width={device.phone ? '100%' : 600}
        placement="right"
      >
        <Button
          onClick={onClose}
          to="/purchase-orders/create"
          type="link"
          block
          icon="shopping-cart"
          className={classes.button}
        >
          Create Purchase Order
        </Button>
        <TransferSaleStock
          type="link"
          block
          className={classes.button}
          icon="reload"
        />
        <Button
          onClick={onClose}
          to="/request-stock-transfers"
          type="link"
          block
          icon="swap"
          className={classes.button}
        >
          Create Stock Transfer
        </Button>
        <Button
          onClick={onClose}
          to="/reports/top-seller/average"
          type="link"
          block
          icon="bar-chart"
          className={classes.button}
        >
          View Top Selling Products
        </Button>
        <Button
          onClick={onClose}
          to="/products/create"
          type="link"
          block
          icon="plus"
          className={classes.button}
        >
          Create Product
        </Button>
        <Button
          target="_blank"
          href={`${POS_DOMAIN}/`}
          type="link"
          block
          icon="arrow-right"
          className={classes.button}
        >
          Open POS App
        </Button>
      </Drawer>
    </>
  );
}
