import { useQuery } from '@apollo/react-hooks';
import apollo from 'core/apollo';
import gql from 'graphql-tag';

export default function useBuyerCategories() {
  return useQuery(QUERY, getBuyerCategoriesOptions());
}

export function fetchBuyerCategories() {
  return apollo.query(getBuyerCategoriesOptions());
}

export function getBuyerCategoriesOptions() {
  return {
    query: QUERY,
    fetchPolicy: 'cache-first'
  };
}

const QUERY = gql`
  query {
    result: buyer_categories {
      buyer_id
      category_id
      user {
        id
        first_name
        last_name
      }
    }
  }
`;
