import { useCallback, useEffect, useRef, useState } from 'react';

export default function useInstallApp() {
  const [show, setShow] = useState(
    typeof window.localStorage.getItem('installApp') === 'undefined'
  );
  const deferredPrompt = useRef();

  useEffect(() => {
    window.addEventListener('beforeinstallprompt', e => {
      e.preventDefault();
      deferredPrompt.current = e;
      setShow(true);
    });
  }, []);

  const install = useCallback(() => {
    setShow(false);
    deferredPrompt.current.prompt();
    deferredPrompt.current.userChoice.then(choiceResult => {
      if (choiceResult.outcome === 'accepted') {
        window.localStorage.setItem('installApp', 'yes');
      } else {
        window.localStorage.setItem('installApp', 'no');
      }
      deferredPrompt.current = null;
    });
  }, []);

  return [show, install];
}
