import React from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import useMiniForm from 'hooks/useMiniForm';
import exportQuery from 'queries/inventory/inventory-export.graphql';
import { Col, Form, Modal, Row } from 'antd';
import Page from 'ui/Page';
import SelectInput from 'components/input/SelectInput';
import StoreInput from 'components/input/StoreInput';
import CategoryInputNew from 'components/input/CategoryInput';
import SupplierInput from 'components/input/SupplierInput';
import ProductInput from 'components/input/ProductInput';
import SubmitButton from 'ui/SubmitButton';

let initialValues = {
  inRange: null,
  inStock: false
};

export default function OutOfStockExport() {
  let apollo = useApolloClient();

  let form = useMiniForm({
    initialValues,
    async onSubmit(values, event) {
      event.preventDefault();
      try {
        await apollo.query({
          query: exportQuery,
          fetchPolicy: 'no-cache',
          variables: {
            filters: {
              ...values,
              sku: values.sku ? String(values.sku) : undefined
            }
          }
        });
        form.reset();
        Modal.success({
          okText: 'Close',
          title: 'Your Export is Processing',
          content: 'We will email you when it is ready.'
        });
      } catch (error) {
        Modal.error({
          title: error.message
        });
      }
    }
  });

  const canExport = true;

  return (
    <Page width="small">
      <Page.Header backTo="/exports/inventory" title="Out of Stock Export" />
      <Page.Content>
        <form onSubmit={form.submit}>
          <Row gutter={16}>
            <Col md={12}>
              <Form.Item label="Store Range Status">
                <SelectInput {...form.getInputPropsFor('inRange')}>
                  <SelectInput.Option value={null}>All</SelectInput.Option>

                  <SelectInput.Option value={true}>In Range</SelectInput.Option>
                  <SelectInput.Option value={false}>
                    Not In Range
                  </SelectInput.Option>
                </SelectInput>
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item label="Stock Status">
                <SelectInput {...form.getInputPropsFor('inStock')}>
                  <SelectInput.Option value={false}>
                    Out Of Stock
                  </SelectInput.Option>
                </SelectInput>
              </Form.Item>
            </Col>
          </Row>

          <Form.Item label="Stores">
            <StoreInput
              {...form.getInputPropsFor('storeIds')}
              mode="multiple"
              placeholder="Leave empty to select all stores"
            />
          </Form.Item>

          <Form.Item label="Categories">
            <CategoryInputNew
              {...form.getInputPropsFor('categoryIds')}
              mode="multiple"
              placeholder="Choose categories..."
            />
          </Form.Item>

          <Form.Item label="Suppliers">
            <SupplierInput
              {...form.getInputPropsFor('supplierIds')}
              mode="multiple"
              placeholder="Choose suppliers..."
            />
          </Form.Item>

          <Form.Item label="Product">
            <ProductInput
              {...form.getInputPropsFor('sku')}
              placeholder="Search for product..."
              valueKey="sku"
            />
          </Form.Item>

          <SubmitButton
            disabled={!canExport}
            icon="export"
            loading={form.submitting}
          >
            Export
          </SubmitButton>
        </form>
      </Page.Content>
    </Page>
  );
}
