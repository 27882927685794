import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

const query = gql`
  mutation(
    $codes: [String!]!
    $from_store_id: Int
    $from_warehouse_id: Int
    $from_product_id: Int!
    $to_product_id: Int!
    $to_store_id: Int
    $to_warehouse_id: Int
  ) {
    result: transferSaleStockToHireStock(
      codes: $codes
      from_store_id: $from_store_id
      from_warehouse_id: $from_warehouse_id
      from_product_id: $from_product_id
      to_product_id: $to_product_id
      to_store_id: $to_store_id
      to_warehouse_id: $to_warehouse_id
    )
  }
`;

export default function useTransferSaleStockToHireStock() {
  return useMutation(query);
}
