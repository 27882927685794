import { useQuery } from '@apollo/react-hooks';
import apollo from 'core/apollo';
import query from 'queries/store/stores.graphql';

export default function useStores() {
  return useQuery(query, getStoresQueryOptions());
}

export function fetchStores() {
  return apollo.query(getStoresQueryOptions());
}

export function getStoresQueryOptions() {
  return {
    query,
    fetchPolicy: 'cache-first'
  };
}
