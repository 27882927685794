import { useEffect } from 'react';
import auth from 'core/auth';

export default function Logout() {
  useEffect(() => {
    auth.logout();
  }, []);

  return null;
}
