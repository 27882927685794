import styled, { css } from 'styled-components';
import { colors } from 'config/antd';
import Button from './Button';

let successCss = css`
  background-color: ${colors['success-color']} !important;
  border-color: transparent !important;
  color: #fff !important;

  &:hover,
  &:focus {
    opacity: 0.9;
  }
`;

export default styled(Button)`
  ${props => !props.disabled && successCss}
`;
