import { useEffect, useRef, useState } from 'react';
import { getScreenData } from 'utils/screen';

function useScreenData() {
  const [data, setData] = useState(getScreenData);
  const timeoutRef = useRef();

  function handleResize() {
    if (timeoutRef.current) {
      window.clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = window.setTimeout(() => {
      setData(getScreenData());
    }, 500);
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return data;
}

function useIsMobileScreen() {
  return useScreenData().isMobile;
}

export { useIsMobileScreen, useScreenData };
