import React from 'react';
import { Card, Col, Row } from 'antd';
import useMiniForm from 'hooks/useMiniForm';
import Form from 'ui/Form';
import TextInput from 'components/input/TextInput';
import TimezoneInput from 'components/input/TimezoneInput';
import FullAddressInput from 'components/input/FullAddressInput';
import classes from '../../../components/coupons/steps/styles.module.css';
import get from 'lodash/get';

export default function Step1({ form, errors }) {
  const getErrorPropsFor = name => {
    const err = get(errors, name);
    if (err) {
      return {
        validateStatus: 'error',
        help: err
      };
    }
  };

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <Card className={classes.card} title="1. User Details">
            <Form.Item label="First Name" {...getErrorPropsFor('first_name')}>
              <TextInput
                placeholder="First Name"
                {...form.getInputPropsFor('first_name')}
              />
            </Form.Item>

            <Form.Item label="Last Name" {...getErrorPropsFor('last_name')}>
              <TextInput
                placeholder="Last Name"
                {...form.getInputPropsFor('last_name')}
              />
            </Form.Item>

            <Form.Item label="Email" {...getErrorPropsFor('email')}>
              <TextInput
                placeholder="Email"
                {...form.getInputPropsFor('email')}
              />
            </Form.Item>
          </Card>
        </Col>
      </Row>
    </>
  );
}
