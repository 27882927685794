import React, { Children, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { PageHeader, Skeleton } from 'antd';
import { breakpoints } from 'config/antd';
import { useIsMobileScreen } from 'hooks/screen';

const Container = styled('div')`
  padding: 16px;

  @media (min-width: ${breakpoints.md}px) {
    padding: 32px;
  }
`;

const PageHeaderWrapper = styled('div')`
  margin: 0 0 32px;

  .ant-page-header {
    padding: 0;

    .ant-page-header-heading-title {
      font-size: 18px;
      font-weight: normal;
    }

    .ant-page-header-heading-extra {
      right: 0;
      top: 0;
    }
  }
`;

function Header({ backTo, title, ...rest }) {
  let history = useHistory();

  useEffect(() => {
    document.title = `${title} - ShoppyCommerce`;
  }, [title]);

  let getOnBack = () => {
    if (backTo) {
      if (typeof backTo === 'string') {
        return () => history.push(backTo);
      } else if (history.length > 0) {
        return () => history.goBack();
      }
    }
  };

  return (
    <PageHeaderWrapper>
      <PageHeader title={title} onBack={getOnBack()} {...rest} />
    </PageHeaderWrapper>
  );
}

const Content = styled('div')`
  ${props => (props.maxWidth ? `max-width: ${props.maxWidth}px;` : undefined)};
`;

const WrapperWithNav = styled('div')`
  display: flex;

  .nav-wrapper {
    border-right: 1px solid #f5f5f5;
    width: 230px;
  }

  .main-wrapper {
    flex-grow: 1;
    max-width: calc(100% - 230px);
  }
`;

const Nav = styled('div')`
  min-height: 100%;
  padding: 24px 0;

  .ant-menu-inline {
    border-right: none !important;
  }
`;

export default function Page({ bg, children, loading, notFound, status }) {
  const isMobile = useIsMobileScreen();

  useEffect(() => {
    document.body.style.backgroundColor = bg || '#fff';
  }, [bg]);

  let nav;
  let other = [];

  Children.forEach(children, child => {
    if (child.type === Nav) {
      nav = child;
    } else {
      other.push(child);
    }
  });

  let content;

  if (typeof loading === 'undefined') {
    if (status === 'loading') {
      content = <Skeleton />;
    } else if (status === '404') {
      content = 'Not Found';
    } else {
      content = other;
    }
  } else {
    if (loading) {
      content = <Skeleton />;
    } else if (notFound) {
      content = 'Not Found';
    } else {
      content = other;
    }
  }

  if (nav) {
    if (isMobile) {
      return (
        <>
          {nav}
          <Container>{content}</Container>
        </>
      );
    }

    return (
      <WrapperWithNav>
        <div className="nav-wrapper">{nav}</div>
        <div className="main-wrapper">
          <Container>{content}</Container>
        </div>
      </WrapperWithNav>
    );
  }

  return <Container>{content}</Container>;
}

Page.Nav = Nav;
Page.Header = Header;
Page.Content = Content;
