import React from 'react';
import { Modal as AntModal } from 'antd';
import { useScreenData } from 'hooks/screen';

export default function Modal({ width, ...props }) {
  const screen = useScreenData();

  const finalWidth = screen.width > 500 ? 500 : screen.width - 50;

  return <AntModal width={width || finalWidth} {...props} />;
}

Modal.confirm = AntModal.confirm;
Modal.error = AntModal.error;
Modal.info = AntModal.info;
Modal.success = AntModal.success;
Modal.warning = AntModal.warning;
