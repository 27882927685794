import get from 'lodash/get';
import isEqual from 'lodash/isEqual';

function isEmpty(value) {
  return (
    typeof value === 'undefined' ||
    value === undefined ||
    value === null ||
    value === ''
  );
}

export const validators = {
  fullAddress(name, value) {
    let address = value || {};
    let requiredFields = [
      { key: 'address_1', label: 'Address' },
      { key: 'postal_code', label: 'Postal Code' },
      { key: 'city', label: 'City' },
      { key: 'state', label: 'State' },
      { key: 'country_code', label: 'Country' }
    ];
    let emptyFields = requiredFields.filter(field =>
      isEmpty(address[field.key])
    );
    if (emptyFields.length > 0) {
      return `You must fill in the ${emptyFields
        .map(field => field.label)
        .join(', ')} ${emptyFields.length === 1 ? 'field' : 'fields'}.`;
    }
  },
  isEmail(name, value) {
    const regex = new RegExp(
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/,
      'gm'
    );
    if (!regex.test(value)) {
      return `${name} is not valid.`;
    }
  },
  maxLength(name, value, length) {
    if (
      (typeof value === 'number' || typeof value === 'string') &&
      `${value}`.length > length
    ) {
      return `${name} length should not be more than ${length}.`;
    }
  },
  min(name, value, length) {
    if (!isEmpty(value)) {
      const number = Number(value);
      if (isNaN(number)) {
        return 'Must be a valid number.';
      } else if (number < length) {
        return `Must be at least ${length}`;
      }
    }
  },
  minLength(name, value, length) {
    if (
      (typeof value === 'number' || typeof value === 'string') &&
      `${value}`.length < length
    ) {
      return `${name} length should be at least ${length}.`;
    } else if (Array.isArray(value) && value.length < length) {
      return `You must choose at least ${length} ${name}.`;
    }
  },
  required(name, value) {
    if (isEmpty(value)) {
      return `${name} is required.`;
    }
  },
  isEqual(a, b, message) {
    if (!isEqual(a, b)) {
      return message;
    }
  }
};

function validateSingleField(name, values, rule, label) {
  let value = get(values, name);
  let finalLabel = label || name;

  if (typeof rule === 'string') {
    let rules = rule.split('|');

    for (let i = 0; i < rules.length; i++) {
      let [ruleName, params] = rules[i].split(':');
      let ruleParams = params ? params.split(',') : [];
      let error = validators[ruleName](finalLabel, value, ...ruleParams);

      if (error) {
        return error;
      }
    }
  }

  if (typeof rule === 'function') {
    return rule(value, values, validators);
  }

  if (rule && rule.rule) {
    return validateSingleField(name, values, rule.rule, rule.label);
  }
}

export default function validator(
  rules = {},
  genericMessage = 'Validation errors'
) {
  return function(values = {}) {
    const errors = {};
    let hasError = false;

    Object.keys(rules).forEach(name => {
      const message = validateSingleField(name, values, rules[name]);
      if (
        (typeof message === 'string' && message.length > 0) ||
        (Array.isArray(message) && message.length > 0)
      ) {
        errors[name] = message;
        hasError = true;
      }
    }, {});

    if (hasError) {
      return {
        errors,
        message: genericMessage
      };
    }
  };
}
