import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import get from 'lodash/get';
import { FormBuilder, FormField, useForm, useFormMeta } from 'react-hooks-form';
import {
  Alert,
  Button,
  DatePicker as AntDatePicker,
  Form as AntForm,
  Input,
  message,
  Radio as AntRadio,
  Select as AntSelect,
  Switch as AntSwitch,
  InputNumber as AntInputNumber
} from 'antd';
import router from 'core/router';
import useAsyncDataReducer from 'hooks/useAsyncDataReducer';
import useDebounceCallback from 'hooks/useDebounceCallback';
import AutoCompleteInput from 'components/input/AutoCompleteInput';
import ColorInput from 'components/input/ColorInput';
import RadioGroupInput from 'components/input/RadioGroupInput';
import validator, { validators } from './validation';

const { TextArea: AntTextArea } = Input;

/* Select input */

function AsyncSelect(props) {
  const {
    children,
    initialOptions,
    onSearch,
    searchOnMount,
    ...selectProps
  } = props;

  const { actions, data: options, loading } = useAsyncDataReducer({
    data: initialOptions || []
  });

  const handleSearch = useDebounceCallback(
    async query => {
      actions.loading();
      const result = await onSearch(query);
      actions.loaded(result || []);
    },
    [onSearch]
  );

  useEffect(() => {
    if (searchOnMount) {
      handleSearch('');
    }
  }, []);

  return (
    <AntSelect
      {...selectProps}
      filterOption={false}
      loading={loading}
      onSearch={handleSearch}
      showSearch
    >
      {options.map((opt, key) => (
        <AntSelect.Option key={key} value={opt.value}>
          {opt.label}
        </AntSelect.Option>
      ))}
    </AntSelect>
  );
}

AsyncSelect.defaultProps = {
  initialOptions: [],
  searchOnMount: false
};

function Select(props) {
  if (typeof props.onSearch === 'function') {
    return <AsyncSelect dropdownMatchSelectWidth={false} {...props} />;
  } else {
    return <AntSelect dropdownMatchSelectWidth={false} {...props} />;
  }
}

Select.Option = AntSelect.Option;

function SelectField(props) {
  return <FormField {...props} component={Select} />;
}

SelectField.Option = Select.Option;

/* Text input */

function TextInput({ onChange, ...rest }) {
  function handleChange(event) {
    if (typeof onChange === 'function') {
      onChange(event.target.value);
    }
  }

  return <Input onChange={handleChange} {...rest} />;
}

function TextField(props) {
  return <FormField {...props} component={TextInput} />;
}

/* Text Area */
function TextAreaComponent({ onChange, ...rest }) {
  function handleChange(event) {
    if (typeof onChange === 'function') {
      onChange(event.target.value);
    }
  }

  return <AntTextArea onChange={handleChange} {...rest} />;
}

function TextArea(props) {
  return <FormField {...props} component={TextAreaComponent} />;
}

/* Date Picker */
function DatePickerField(props) {
  return <FormField {...props} component={AntDatePicker} />;
}

function DateRangePickerField(props) {
  return <FormField {...props} component={AntDatePicker.RangePicker} />;
}

function InputNumber(props) {
  return <FormField {...props} component={AntInputNumber} />;
}

/* Switch */
function SwitchInput({ onChange, value, ...rest }) {
  function handleChange(checked) {
    if (typeof onChange === 'function') {
      onChange(checked);
    }
  }

  return <AntSwitch onChange={handleChange} checked={value} {...rest} />;
}

function Switch(props) {
  return <FormField {...props} component={SwitchInput} />;
}

/* Radio */
function RadioGroup(props) {
  return <FormField {...props} component={RadioGroupInput} />;
}

function AutoComplete(props) {
  return <FormField {...props} component={AutoCompleteInput} />;
}

/* Color picker */
function ColorPicker(props) {
  return <FormField {...props} component={ColorInput} />;
}

/* Submit button */
function Submit(props) {
  const { children, text, loading, ...buttonProps } = props;
  const { submitting } = useFormMeta();

  return (
    <Button
      {...buttonProps}
      type="primary"
      htmlType="submit"
      loading={submitting || loading}
    >
      {children || text}
    </Button>
  );
}

Submit.defaultProps = {
  text: 'Save'
};

/* Form errors */

const ErrorsWrapper = styled('div')`
  margin-bottom: 20px;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li:first-letter {
      text-transform: capitalize;
    }
  }
`;

function ValidationErrors(props) {
  const ref = useRef();
  const { error, submitFailed, submitting } = useFormMeta();

  useEffect(() => {
    if (submitFailed) {
      // @TODO: Scroll to the error alert using `ref.current`
    }
  }, [submitFailed]);

  let output = null;

  if (submitFailed) {
    output = (
      <ErrorsWrapper>
        <Alert
          message={error.message}
          description={
            error.errors ? (
              <ul>
                {Object.values(error.errors).map((err, key) => (
                  <li key={key}>{err}</li>
                ))}
              </ul>
            ) : null
          }
          type="error"
        />
      </ErrorsWrapper>
    );
  }

  return <div ref={ref}>{output}</div>;
}

/* Form fields */

function normalizeValidationErrors(response) {
  const error = get(response, 'graphQLErrors', []);

  if (error && Array.isArray(error)) {
    let target = error.find(
      e => get(e, 'extensions.code') === 'BAD_USER_INPUT'
    );
    if (target) {
      const errors = get(target, 'extensions.exception.details', []).reduce(
        (errs, item) => ({
          ...errs,
          [item.path[0]]: item.message
        }),
        {}
      );
      return {
        message: target.message,
        errors,
        response: error
      };
    }
    target = error.find(
      e => get(e, 'extensions.code') === 'INTERNAL_SERVER_ERROR'
    );
    if (target) {
      return {
        message: target.message
      };
    }
  }

  return response;
  // if (error && (error.message || error.errors)) {
  //   return error;
  // } else {
  //   let message = 'Something bad happened!';
  //   let errors = {};
  //   if (error && error.graphQLErrors && error.graphQLErrors.length > 0) {
  //     const mainError = error.graphQLErrors.find(
  //       err => err.name === 'UserInputError'
  //     );
  //     if (mainError) {
  //       const reason = mainError.data.reason;
  //       if (typeof reason === 'string') {
  //         message = reason;
  //       } else {
  //         message = mainError.message;
  //         Object.keys(reason).forEach(fieldName => {
  //           errors[fieldName] = reason[fieldName].join(' ');
  //         });
  //       }
  //     }
  //   }
  //   return { message, errors };
  // }
}

function Form(props) {
  const form = useForm();

  function handleSubmit(event) {
    event.preventDefault();
    form.submit();
  }

  return <AntForm onSubmit={handleSubmit} {...props} />;
}

/* Form wrapper */

type FormWrapperProps = {
  destroyOnUnmount?: boolean,
  initialValues?: Object,
  name?: string,
  onSubmit: Function,
  onSubmitComplete?: Function,
  onSubmitFail?: Function,
  onSubmitSucceed?: Function,
  successMessage?: string | Function,
  successUrl?: string | Function,
  validate?: Function
};

function FormWrapper(props: FormWrapperProps) {
  const {
    children,
    onSubmit,
    successMessage: sMess,
    successUrl: sUrl,
    ...formProps
  } = props;

  function handleSubmit(...submitParams) {
    return new Promise((resolve, reject) => {
      onSubmit(...submitParams)
        .then(response => {
          if (sMess) {
            message.success(
              typeof sMess === 'string' ? sMess : sMess(response)
            );
          }
          if (sUrl) {
            router.go(typeof sUrl === 'string' ? sUrl : sUrl(response));
          }
          resolve(response);
        })
        .catch(error => {
          reject(normalizeValidationErrors(error));
        });
    });
  }

  return (
    <FormBuilder {...formProps} onSubmit={handleSubmit}>
      <Form {...formProps}>{children}</Form>
    </FormBuilder>
  );
}

AntForm.Item.defaultProps.colon = false;

FormWrapper.Item = AntForm.Item;
FormWrapper.Field = FormField;
FormWrapper.Select = SelectField;
FormWrapper.InputNumber = InputNumber;
FormWrapper.Text = TextField;
FormWrapper.TextArea = TextArea;
FormWrapper.DatePicker = DatePickerField;
FormWrapper.DateRangePicker = DateRangePickerField;
FormWrapper.Switch = Switch;
FormWrapper.RadioGroup = RadioGroup;
FormWrapper.Radio = AntRadio;
FormWrapper.AutoComplete = AutoComplete;
FormWrapper.ColorPicker = ColorPicker;
FormWrapper.Submit = Submit;
FormWrapper.Errors = ValidationErrors;
FormWrapper.defaultProps = {
  destroyOnUnmount: true
};

export { validator, validators };
export { useForm, useFormMeta, useFormFieldValue } from 'react-hooks-form';
export default FormWrapper;
