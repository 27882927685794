import React, { useCallback } from 'react';
import get from 'lodash/get';
import gql from 'graphql-tag';
import { useApolloClient } from '@apollo/react-hooks';
import SelectInput from './AsyncSearchSelectInput';

export default function ProductInput(props) {
  let { mode, value, valueKey, ...rest } = props;

  let apollo = useApolloClient();

  let searchByKeyword = useCallback(
    async keyword => {
      let result = await apollo.query({
        query: QUERY,
        variables: {
          limit: 10,
          where: {
            _or: [
              {
                name: {
                  _ilike: `%${keyword}%`
                }
              },
              {
                sku: {
                  _ilike: `%${keyword}%`
                }
              }
            ]
          }
        }
      });

      return get(result, 'data.result', []).map(product => ({
        label: product.name,
        description: `SKU: ${product.sku}`,
        value: product[valueKey]
      }));
    },
    [apollo, valueKey]
  );

  let searchByValue = useCallback(
    async values => {
      let result = await apollo.query({
        query: QUERY,
        variables: {
          where: {
            [valueKey]: {
              _in: values
            }
          }
        }
      });

      return get(result, 'data.result', []).map(product => ({
        label: product.name,
        description: `SKU: ${product.sku}`,
        value: product[valueKey]
      }));
    },
    [apollo, valueKey]
  );

  return (
    <SelectInput
      mode={mode}
      searchByValue={searchByValue}
      searchByKeyword={searchByKeyword}
      value={value}
      {...rest}
    />
  );
}

ProductInput.defaultProps = {
  allowClear: true,
  valueKey: 'id'
};

const QUERY = gql`
  query($limit: Int, $where: product_view_bool_exp) {
    result: product_view(limit: $limit, where: $where) {
      id
      sku
      name
    }
  }
`;
