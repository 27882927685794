import { useQuery } from '@apollo/react-hooks';
import apollo from 'core/apollo';
import query from 'queries/warehouse/warehouses.graphql';

export default function useWarehouses() {
  return useQuery(query, getQueryOptions());
}

export function fetchWarehouses() {
  return apollo.query(getQueryOptions());
}

export function getQueryOptions() {
  return {
    query,
    fetchPolicy: 'cache-first'
  };
}
