import get from 'lodash/get';
import { getApolloContext } from '@apollo/react-hooks';
import { message } from 'antd';
import { getGlobalData, setGlobalData } from 'core/store';
import { v4 as uid } from 'uuid';
import { round } from './format';
import apollo from 'core/apollo';
import query from 'queries/store/stores.graphql';
import env from '../config/env';

const EXPECTED_COMPANY_ID = env('NODE_ENV') === 'development' ? 5 : 7;

setGlobalData('cart', JSON.parse(window.localStorage.getItem('cart')));

export default function cart() {
  function persist() {
    window.localStorage.setItem('cart', JSON.stringify(getGlobalData('cart')));
  }

  return {
    items() {
      return get(getGlobalData('cart'), 'items', []);
    },

    isBackOrder() {
      return !!get(getGlobalData('cart', 'parent_purchase_order_id'));
    },

    insertBulk(product, storeId) {
      const items = get(getGlobalData('cart'), 'items') || [];
      let supplierId = get(product, 'customSupplierId')
        ? get(product, 'customSupplierId')
        : get(product, 'product_suppliers[0].supplier.id');
      let tax_id = product.buy_tax_id || undefined;
      let unit_price = round(product.last_price || 0);
      const qty = get(product, 'customQty', 1);
      const orderItemId = get(product, 'orderItemId');
      const orderId = get(product, 'orderId');
      const isJob = get(product, 'isJob');
      const orderBatchId = get(product, 'orderBatchId');
      const storesQR = apollo.readQuery({
        query
      });
      const storesResult = get(storesQR, 'result') || [];
      const store = storesResult.find(store => store.id === storeId);
      const user = getGlobalData('user');
      const companyId = get(user, 'company.id');
      let sourceId = storeId;
      let sourceType = 'store';

      if (companyId === EXPECTED_COMPANY_ID && store) {
        const primaryWarehouse = (store.warehouse_stores || []).find(
          ws => ws.primary
        );

        if (primaryWarehouse) {
          sourceId = get(primaryWarehouse, 'warehouse_id');
          sourceType = 'warehouse';
        }
      }

      // Check Item is duplicate to increase QTY
      const duplicateItem = items.find(
        item => item.product_id === product.id && item.store_id === storeId
      );

      if (duplicateItem) {
        setGlobalData('cart', prev => ({
          ...prev,
          items: [
            ...items.filter(item => item.key !== duplicateItem.key),
            {
              key: randomId(),
              hot_order: false,
              unit_price,
              product_id: product.id,
              is_job: isJob,
              order_id: orderId,
              batch_id: orderBatchId,
              order_item_id: orderItemId,
              quantity: duplicateItem.quantity + qty,
              store_id: sourceType === 'store' ? storeId : undefined,
              warehouse_id: sourceType === 'warehouse' ? sourceId : undefined,
              supplier_id: supplierId,
              public_notes: [],
              tax_id
            }
          ]
        }));

        persist();
      } else {
        setGlobalData('cart', prev => ({
          ...prev,
          items: [
            ...items,
            {
              key: randomId(),
              hot_order: false,
              unit_price,
              product_id: product.id,
              is_job: isJob,
              order_id: orderId,
              batch_id: orderBatchId,
              order_item_id: orderItemId,
              quantity: qty,
              store_id: sourceType === 'store' ? storeId : undefined,
              warehouse_id: sourceType === 'warehouse' ? sourceId : undefined,
              supplier_id: supplierId,
              public_notes: [],
              tax_id
            }
          ]
        }));

        persist();
      }
    },

    add(product, storeIds, bulk = false) {
      let supplierId = get(product, 'customSupplierId')
        ? get(product, 'customSupplierId')
        : get(product, 'product_suppliers[0].supplier.id');
      let tax_id = product.buy_tax_id || undefined;
      let unit_price = round(product.last_price || 0);
      const qty = get(product, 'customQty', 1) || 1;
      const orderItemId = get(product, 'orderItemId');
      const orderId = get(product, 'orderId');
      const orderBatchId = get(product, 'orderBatchId');

      let stores =
        Array.isArray(storeIds) && storeIds.length > 0 ? storeIds : [undefined];

      for (const storeId of stores) {
        let items = get(getGlobalData('cart'), 'items', []);
        const duplicateItem = items.find(
          item => item.product_id === product.id && item.store_id === storeId
        );
        const storesQR = apollo.readQuery({
          query
        });
        const storesResult = get(storesQR, 'result') || [];
        const store = storesResult.find(store => store.id === storeId);
        const user = getGlobalData('user');
        const companyId = get(user, 'company.id');
        let sourceId = storeId;
        let sourceType = 'store';

        if (companyId === EXPECTED_COMPANY_ID && store) {
          const primaryWarehouse = (store.warehouse_stores || []).find(
            ws => ws.primary
          );

          if (primaryWarehouse) {
            sourceId = get(primaryWarehouse, 'warehouse_id');
            sourceType = 'warehouse';
          }
        }

        if (duplicateItem) {
          message.warn('Product already in cart, quantity increased.');
          setGlobalData('cart', prev => ({
            ...prev,
            items: prev.items.map(item => {
              if (item.key === duplicateItem.key) {
                return {
                  ...item,
                  quantity: duplicateItem.quantity + 1
                };
              }
              return item;
            })
          }));

          persist();
        } else {
          setGlobalData('cart', prev => ({
            ...prev,
            items: [
              ...items,
              {
                key: randomId(),
                hot_order: false,
                unit_price,
                product_id: product.id,
                order_id: orderId,
                batch_id: orderBatchId,
                order_item_id: orderItemId,
                quantity: qty,
                store_id: sourceType === 'store' ? sourceId : undefined,
                warehouse_id: sourceType === 'warehouse' ? sourceId : undefined,
                supplier_id: supplierId,
                public_notes: [],
                tax_id
              }
            ]
          }));

          persist();
        }
      }
      if (!bulk) message.info('Item added to Purchase Order');
    },

    addBackOrderItem({
      parentId,
      storeId,
      warehouseId,
      supplierId,
      items,
      reference
    }) {
      if (!this.isBackOrder) {
        this.truncate();
      }
      let prevItems = get(getGlobalData('cart'), 'items', []);

      setGlobalData('cart', () => ({
        items: [
          ...prevItems,
          ...items.map(item => {
            const product = get(item, 'product_view');
            let tax_id = item.tax_id || undefined;
            let unit_price = item.unit_price || 0;
            return {
              key: randomId(),
              hot_order: false,
              unit_price,
              product_id: product.id,
              quantity: item.back_order_qty,
              store_id: storeId,
              warehouse_id: warehouseId,
              supplier_id: supplierId,
              public_notes: [],
              back_order_po_item_id: item.id,
              tax_id
            };
          })
        ],
        reference,
        parent_purchase_order_id: parentId
      }));

      persist();

      if (items.length > 1) {
        message.info('Items added to Purchase Order');
      } else {
        message.info('Item added to Purchase Order');
      }
    },

    addBackOrderItems({
      parentId,
      storeId,
      warehouseId,
      supplierId,
      items,
      reference
    }) {
      this.truncate();

      setGlobalData('cart', () => ({
        items: items.map(item => {
          const product = get(item, 'product_view');
          let tax_id = item.tax_id || undefined;
          let unit_price = item.unit_price || 0;
          return {
            key: randomId(),
            hot_order: false,
            unit_price,
            product_id: product.id,
            quantity: item.back_order_qty,
            store_id: storeId,
            warehouse_id: warehouseId,
            supplier_id: supplierId,
            public_notes: item.public_notes || [],
            back_order_po_item_id: item.id,
            tax_id
          };
        }),
        reference,
        parent_purchase_order_id: parentId
      }));

      persist();

      if (items.length > 1) {
        message.info('Items added to Purchase Order');
      } else {
        message.info('Item added to Purchase Order');
      }
    },

    duplicate(key) {
      let items = get(getGlobalData('cart'), 'items', []);
      let result = items.find(item => item.key === key);
      if (result) {
        setGlobalData('cart', prev => ({
          ...prev,
          items: items.reduce((nextItems, item) => {
            if (item.key === key) {
              return [...nextItems, item, { ...result, key: randomId() }];
            }
            return [...nextItems, item];
          }, [])
        }));
        persist();
        message.info('Product duplicated.');
      }
    },

    update(key, data) {
      setGlobalData('cart', prev => ({
        ...prev,
        items: this.items().map(item => {
          if (item.key === key) {
            return {
              ...item,
              ...data
            };
          }
          return item;
        })
      }));

      persist();
    },

    updateField(name, value) {
      setGlobalData('cart', prev => ({
        ...prev,
        [name]: value
      }));
      persist();
    },

    remove(key) {
      const multiple = Array.isArray(key);
      setGlobalData('cart', prev => ({
        ...prev,
        items: get(getGlobalData('cart'), 'items', []).filter(item =>
          multiple ? key.indexOf(item.key) === -1 : item.key !== key
        )
      }));
      persist();
      message.info(
        `${multiple ? 'Products' : 'Product'} removed from the cart.`
      );
    },

    truncate() {
      setGlobalData('cart', { items: [] });
      persist();
    }
  };
}

function randomId() {
  return uid();
}
