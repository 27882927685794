import React, { useCallback, useEffect, useState } from 'react';
import get from 'lodash/get';
import Fuse from 'fuse.js';
import useCategories from 'api/category/useCategories';
import useBuyerCategories from 'api/category/useBuyerCategories';
import { normalizeCategories } from 'utils/data';
import { getName } from 'utils/common';
import { useCurrentUser } from 'hooks/user';
import SelectInput from './SelectInput';

export default function CategoryInput(props) {
  const {
    allowSelectAllOnEmpty,
    disabled,
    filter,
    placeholder,
    value,
    showBuyers,
    lockBuyers,
    max,
    mode,
    onChange,
    ...rest
  } = props;
  const user = useCurrentUser();

  const [options, setOptions] = useState([]);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [indexes, setIndexes] = useState();

  const { data, loading } = useCategories();
  const { data: buyerCategoriesData } = useBuyerCategories();
  const buyerCategories = get(buyerCategoriesData, 'result', []);

  useEffect(() => {
    const normalizedCategories = normalizeCategories(get(data, 'result', []));
    setOptions(normalizedCategories);
    setFilteredOptions(normalizedCategories);
    setIndexes(createCategoriesIndex(normalizedCategories));
  }, [data]);

  const onSearch = useCallback(
    keyword => {
      const results = keyword
        ? indexes.search(keyword).map(row => row.item)
        : options;
      setFilteredOptions(results);
    },
    [indexes, options]
  );

  const handleSelect = () => {
    if (max && mode === 'multiple' && (value || []).length + 1 > max) {
      onChange(value);
    }
  };

  return (
    <SelectInput
      disabled={disabled || loading}
      loading={loading}
      placeholder={loading ? 'Loading...' : placeholder}
      value={loading ? undefined : value}
      onSearch={onSearch}
      onChange={onChange}
      onSelect={handleSelect}
      filterOption={false}
      mode={mode}
      {...rest}
    >
      {allowSelectAllOnEmpty && (
        <SelectInput.Option value="">All categories</SelectInput.Option>
      )}
      {filteredOptions.map(category => {
        const buyerCategory = buyerCategories.find(
          bc => bc.category_id === category.id
        );
        const lock = lockBuyers && buyerCategory;
        return (
          <SelectInput.Option
            key={category.id}
            value={category.id}
            disabled={lock}
          >
            {category.nameLeveled}
            {buyerCategory && showBuyers && (
              <>
                &nbsp;&nbsp;
                <small>
                  - Assigned To{' '}
                  {buyerCategory.user && buyerCategory.user.id === user.id
                    ? 'You'
                    : getName(get(buyerCategory, 'user'))}
                </small>
              </>
            )}
          </SelectInput.Option>
        );
      })}
    </SelectInput>
  );
}

function createCategoriesIndex(data) {
  const options = {
    findAllMatches: true,
    ignoreLocation: true,
    includeScore: true,
    includeMatches: true,
    useExtendedSearch: true,
    ignoreFieldNorm: true,
    keys: ['nameLeveled', 'name', 'searchString'],
    minMatchCharLength: 1,
    threshold: 0.1
  };

  return new Fuse(data, options);
}

CategoryInput.defaultProps = {
  allowClear: true,
  allowSelectAllOnEmpty: false
};
