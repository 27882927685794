import React, { useState } from 'react';
import get from 'lodash/get';
import styled from 'styled-components';
import { Avatar, Badge, Icon, Menu } from 'antd';
import SuccessButton from 'ui/SuccessButton';
import Drawer from 'ui/Drawer';
import { breakpoints } from 'config/antd';
import auth from 'core/auth';
import { setGlobalData, useGlobalData } from 'core/store';
import { useCartData } from 'hooks/cart';
import { useCurrentUser } from 'hooks/user';
import useDevice from 'hooks/useDevice';
import useGetConfig from 'hooks/useGetConfig';
import Cart from 'components/cart/Cart';
import QuickAccessButton from './QuickAccessButton';
import Button from 'ui/Button';
import { getPersonName } from '../../utils/common';

export default function TopNav() {
  const user = useCurrentUser();
  const getConfig = useGetConfig();
  const companyName = get(getConfig('COMPANY_NAME'), 'value');

  const device = useDevice();

  const cart = useCartData();

  const collapsed = useGlobalData('sideNavCollapsed');

  const [showCart, setShowCart] = useState(false);

  const handleToggleSideNav = () => {
    setGlobalData('sideNavCollapsed', prev => !prev);
  };

  const closeCart = () => setShowCart(false);

  const openCart = () => setShowCart(true);

  const openHelpWidget = () => {
    window.FreshworksWidget('open');
  };

  return (
    <>
      <Navbar>
        <button className="toggle-sidenav" onClick={handleToggleSideNav}>
          <Icon type={collapsed ? 'menu-unfold' : 'menu-fold'} />
        </button>

        <Menu mode="horizontal">
          <Menu.Item>
            <QuickAccessButton />
          </Menu.Item>

          <Menu.Item onClick={openCart}>
            <Button shape="circle">
              <Badge count={cart.items.length}>
                <Icon
                  type="shopping-cart"
                  style={{ fontSize: 18, marginRight: 1 }}
                />
              </Badge>
            </Button>
          </Menu.Item>

          <Menu.SubMenu
            style={{ marginLeft: 16 }}
            key="user"
            title={
              <>
                <UserName>
                  {user.first_name}
                  &nbsp;&nbsp;
                </UserName>
                <Avatar>{user.first_name[0]}</Avatar>
              </>
            }
          >
            <div
              style={{
                padding: '8px 16px 0px',
                lineHeight: '22px',
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <div>User:</div>
              <small>{getPersonName(user)}</small>
              <small>{get(user, 'email', 'N/A')}</small>
            </div>
            <div style={{ padding: '8px 16px 0px', lineHeight: '22px' }}>
              <div>Company:</div>
              <small>{companyName}</small>
            </div>
            <Menu.Item key="help" onClick={openHelpWidget}>
              <Icon type="customer-service" />
              Help
            </Menu.Item>
            <Menu.Item onClick={auth.logout}>
              <Icon type="logout" /> Logout
            </Menu.Item>
          </Menu.SubMenu>
        </Menu>
      </Navbar>

      <Drawer onClose={closeCart} title="Cart" visible={showCart}>
        <Cart cartData={cart} onClose={closeCart} />
      </Drawer>
    </>
  );
}

const UserName = styled('span')`
  display: none;

  @media (min-width: 768px) {
    display: inline;
  }
`;

const Navbar = styled.div`
  align-items: center;
  background-color: #f9f9f9;
  border-bottom: 1px solid #e7e7e7;
  display: flex;
  height: 60px;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 999;

  @media (min-width: ${breakpoints.md}px) {
    padding-left: 16px;
    padding-right: 12px;
  }

  button.toggle-sidenav {
    background-color: transparent;
    border: none;
    height: 100%;
    outline: none;
    padding: 0 16px;

    &:active {
      background-color: #f9f9f9;
    }
  }

  ul.ant-menu-horizontal {
    background-color: transparent !important;
    border-bottom: none !important;
    line-height: 59px;
    text-align: right;

    .ant-menu-item,
    .ant-menu-submenu-title {
      border-bottom: none !important;
      margin-top: 0 !important;
      padding: 0 8px !important;

      @media (max-width: ${breakpoints.md - 1}px) {
        padding-left: 16px;
        padding-right: 16px;
      }
    }
  }
`;
