import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { Select } from 'antd';
import styled from 'styled-components';

export default function AsyncSearchSelectInput(props) {
  let {
    disabled,
    loading,
    placeholder,
    searchByKeyword,
    searchByValue,
    value,
    ...rest
  } = props;

  let isInitial = useRef(true);

  let [state, setState] = useState({
    loading: false,
    options: []
  });

  let [extraOptions, setExtraOptions] = useState([]);

  let finalOptions = useMemo(
    () =>
      extraOptions.reduce((finalOptions, option) => {
        if (state.options.find(opt => opt.value === option.value)) {
          return finalOptions;
        }
        return [option, ...finalOptions];
      }, state.options),
    [extraOptions, state.options]
  );

  let valueArray = useMemo(() => {
    if (Array.isArray(value)) {
      return value;
    } else if (typeof value !== 'undefined' && value !== '' && value !== null) {
      return [value];
    }
    return [];
  }, [value]);

  let needsExtraOptions =
    valueArray.length > 0 &&
    valueArray.some(
      selectedOpt => !finalOptions.find(opt => opt.value === selectedOpt)
    );

  useEffect(() => {
    async function search() {
      try {
        let options = await searchByValue(valueArray);
        setExtraOptions(options);
      } catch (error) {}
    }

    if (needsExtraOptions) {
      search();
    }
  }, [needsExtraOptions, searchByValue, valueArray]);

  let handleSearch = useCallback(
    async keyword => {
      setState(prevState => ({ ...prevState, loading: true }));
      try {
        let options = await searchByKeyword(keyword);
        setState(prevState => ({ ...prevState, loading: false, options }));
      } catch (e) {
        setState(prevState => ({ ...prevState, loading: false }));
      }
    },
    [searchByKeyword]
  );

  useEffect(() => {
    if (isInitial.current && !needsExtraOptions) {
      isInitial.current = false;
      handleSearch('');
    }
  }, [handleSearch, needsExtraOptions]);

  let finalLoading = loading || state.loading || needsExtraOptions;

  let finalDisabled = disabled || (finalLoading && needsExtraOptions);

  let finalPlaceholder = placeholder;

  let finalValue = value;

  if (value === '' || value === null) {
    finalValue = undefined;
  } else if (Array.isArray(value) && value.length === 0) {
    finalValue = undefined;
  } else if (finalLoading && needsExtraOptions) {
    finalValue = undefined;
    finalPlaceholder = 'Loading...';
  }

  return (
    <Select
      disabled={finalDisabled}
      filterOption={false}
      loading={finalLoading}
      onSearch={handleSearch}
      optionLabelProp="label"
      placeholder={finalPlaceholder}
      value={finalValue}
      showSearch
      {...rest}
    >
      {finalOptions.map((opt, key) => (
        <Select.Option key={key} label={opt.label} value={opt.value}>
          {opt.label}
          {opt.description ? (
            <OptionDescription>{opt.description}</OptionDescription>
          ) : null}
        </Select.Option>
      ))}
    </Select>
  );
}

AsyncSearchSelectInput.defaultProps = {
  allowClear: true,
  dropdownMatchSelectWidth: false
};

let OptionDescription = styled('small')`
  display: block;
  line-height: 1.4;
  opacity: 0.7;
`;
