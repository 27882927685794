import React, { useMemo, useState } from 'react';
import get from 'lodash/get';
import styled from 'styled-components';
import moment from 'moment-timezone';
import { Button, Tag } from 'antd';
import Page from 'components/page';
import { useScheduledSuppliers } from './api/hooks';
import { useWeekNumber } from 'pages/dashboard/api/hooks';

const DayNumber = styled('span')`
  background-color: ${props => (props.isToday ? '#1890ff' : 'transparent')};
  border-radius: 50%;
  color: ${props => (props.isToday ? '#fff' : 'inherit')};
  font-size: 14px;
  line-height: 32px;
  height: 32px;
  margin-bottom: 2px;
  text-align: center;
  width: 32px;
`;

const DayHeader = styled('div')`
  align-items: center;
  background: #fff;
  border-bottom: 1px solid #ddd;
  color: #aaa;
  display: flex;
  flex-direction: column;
  font-size: 13px;
  justify-content: center;
  order: ${props => props.order};
  padding: 15px 10px 10px;
  position: sticky;
  text-align: center;
  top: 0;

  .day {
    font-size: 12px;
    line-height: 12px;
    margin-bottom: 2px;
    text-transform: uppercase;
  }

  .count {
    color: #ccc;
    font-size: 11px;
  }
`;

const DayBody = styled('div')`
  border-right: ${props => (props.order < 13 ? 1 : 0)}px solid #ddd;
  order: ${props => props.order};
  padding: 10px 5px 10px 10px;
  min-height: 200px;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      margin: 0 0 5px;
      padding: 0;
      overflow: hidden;

      .ant-tag {
        line-height: 14px;
        padding-bottom: 3px;
        padding-top: 3px;
        white-space: pre-line;
      }
    }
  }

  p {
    color: #bbb;
    margin: 0;
    padding: 7px 10px;
    text-align: center;
  }
`;

const CalendarWrapper = styled('div')`
  border: 1px solid #ddd;
  border-radius: 4px;
  display: grid;
  grid-template-columns: 14.2857% 14.2857% 14.2857% 14.2857% 14.2857% 14.2857% 14.2857%;
  max-height: calc(100vh - 280px);
  min-width: 900px;
  overflow-y: scroll;
  position: relative;
`;

const CalendarNavigation = styled('div')`
  align-items: center;
  display: flex;
  justify-content: center;

  > span {
    font-size: 16px;
    margin: 0 20px;
  }
`;

const HorizontalWrapper = styled('div')`
  overflow-x: auto;
`;

function Day({ date, data, index, loading }) {
  const sliced = data;

  return (
    <>
      <DayHeader order={index}>
        <div className="day">{moment(date).format('ddd')}</div>
        <DayNumber isToday={moment().isSame(date, 'day')}>
          {moment(date).format('D')}
        </DayNumber>
        <div className="count">
          {loading && <span>Loading...</span>}
          {!loading && data.length === 0 && <span>No supplier</span>}
          {!loading && data.length > 0 && <span>{data.length} Suppliers</span>}
        </div>
      </DayHeader>
      <DayBody order={index + 7}>
        {!loading && sliced.length > 0 && (
          <ul>
            {sliced.map(item => (
              <li key={item.supplier_id}>
                <Tag color="blue">{item.supplier_name}</Tag>
              </li>
            ))}
            {data.length > sliced.length && <li>More...</li>}
          </ul>
        )}
      </DayBody>
    </>
  );
}

export default function SuppliersCalendar() {
  const { data: weekNumberData, loading: weekNumberLoading } = useWeekNumber();
  const weekNumber = get(weekNumberData, 'weekNumber');
  const [startDate, setStartDate] = useState(() => moment().startOf('isoWeek'));

  const endDate = startDate
    .clone()
    .endOf('isoWeek')
    .endOf('day');

  const handleNext = () => {
    setStartDate(prevDate => prevDate.clone().add(1, 'weeks'));
  };

  const handlePrev = () => {
    setStartDate(prevDate => prevDate.clone().subtract(1, 'weeks'));
  };

  const { data, loading } = useScheduledSuppliers({
    startDate: startDate.format(),
    endDate: endDate.format()
  });

  const suppliers = useMemo(
    () => (data && data.scheduledSuppliers ? data.scheduledSuppliers : []),
    [data]
  );

  const dataGrouped = useMemo(() => {
    const dates = {};
    const start = startDate.clone();
    while (!start.isAfter(endDate)) {
      dates[start.format('YYYY-MM-DD')] = [];
      start.add(1, 'days');
    }
    if (!loading) {
      suppliers.forEach(supplier => {
        dates[moment(supplier.date).format('YYYY-MM-DD')].push(supplier);
      });
    }
    return dates;
  }, [startDate, endDate, suppliers, loading]);

  return (
    <Page>
      <Page.Header
        title={`Supplier Re-Stock Schedule ${
          !weekNumberLoading && weekNumber >= 1 ? `- Week ${weekNumber}` : ''
        }`}
        extra={
          <CalendarNavigation>
            <Button icon="left" shape="circle" onClick={handlePrev} />
            <span>
              {startDate.format('YYYY-MM-DD')} - {endDate.format('YYYY-MM-DD')}
            </span>
            <Button icon="right" shape="circle" onClick={handleNext} />
          </CalendarNavigation>
        }
      />

      <Page.Content>
        <HorizontalWrapper>
          <CalendarWrapper>
            {Object.keys(dataGrouped).map((date, index) => (
              <Day
                key={date}
                data={dataGrouped[date]}
                date={date}
                index={index}
                loading={loading}
              />
            ))}
          </CalendarWrapper>
        </HorizontalWrapper>
      </Page.Content>
    </Page>
  );
}
