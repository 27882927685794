import React from 'react';
import get from 'lodash/get';
import { Card, Col, Row } from 'antd';
import Form from 'ui/Form';
import TextInput from 'components/input/TextInput';
import TimezoneInput from 'components/input/TimezoneInput';
import FullAddressInput from 'components/input/FullAddressInput';
import CurrencyInput from 'components/input/CurrencyInput';
import classes from '../../../components/coupons/steps/styles.module.css';

export default function Step2({ form, errors }) {
  const getErrorPropsFor = name => {
    const err = get(errors, name);
    if (err) {
      return {
        validateStatus: 'error',
        help: err
      };
    }
  };

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <Card className={classes.card} title="2. Company Details">
            <Form.Item
              label="Company Name"
              {...getErrorPropsFor('company_name')}
            >
              <TextInput
                placeholder="Company Name"
                {...form.getInputPropsFor('company_name')}
              />
            </Form.Item>

            <Row gutter={[16, 16]}>
              <Col xs={24} lg={12}>
                <Form.Item
                  label="Company Timezone"
                  {...getErrorPropsFor('company_timezone')}
                >
                  <TimezoneInput
                    style={{ width: '100%' }}
                    {...form.getInputPropsFor('company_timezone')}
                    placeholder="Company Timezone"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} lg={12}>
                <Form.Item
                  label="Company Currency"
                  {...getErrorPropsFor('company_currency')}
                >
                  <CurrencyInput
                    disabled
                    style={{ width: '100%' }}
                    {...form.getInputPropsFor('company_currency')}
                    placeholder="Company Currency"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item
              label="Company Address"
              {...getErrorPropsFor('company_address')}
            >
              <FullAddressInput {...form.getInputPropsFor('company_address')} />
            </Form.Item>
          </Card>
        </Col>
      </Row>
    </>
  );
}
