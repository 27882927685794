import get from 'lodash/get';
import * as Sentry from '@sentry/browser';
import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache
} from 'apollo-boost';
import { onError } from 'apollo-link-error';
import { setContext } from 'apollo-link-context';
import ApolloLinkTimeout from 'apollo-link-timeout';
import jwtDecode from 'jwt-decode';
import auth from 'core/auth';

const SPECIAL_CASES = ['constraint-violation', 'validation-failed'];

function createClient(options) {
  const timeoutLink = new ApolloLinkTimeout(210000); // Set timeout to 3.5 minute (210 Seconds)
  let cache = new InMemoryCache();
  let httpLink = new HttpLink(options);
  const timeoutHttpLink = timeoutLink.concat(httpLink);

  const errorLink = onError(({ response, networkError, graphQLErrors }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ message, extensions }) => {
        if (SPECIAL_CASES.includes(extensions.code)) {
          Sentry.captureMessage(message);
        }
      });
    }

    if (networkError && networkError.statusCode === 401) {
      auth.refreshToken();
    }
  });

  const authLink = setContext(async ({ headers }) => {
    const token = localStorage.getItem('id_token');
    const { exp } = jwtDecode(token);
    const expirationTime = exp * 1000 - 60000;
    if (Date.now() >= expirationTime) {
      await auth.refreshToken();
    }

    const newToken = localStorage.getItem('id_token');

    return {
      headers: {
        ...get(options, 'headers'),
        ...headers,
        Authorization: newToken ? `Bearer ${token}` : ''
      }
    };
  });

  const link = ApolloLink.from([errorLink, authLink, timeoutHttpLink]);

  return new ApolloClient({
    cache,
    link,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
        errorPolicy: 'ignore'
      }
    }
  });
}

// @todo: tmpClient will be removed!
export const tmpClient = createClient({
  uri: process.env.REACT_APP_GRAPHQL_URL,
  headers: {
    'hasura-x-company-id': 1
  }
});

export default createClient({
  uri: process.env.REACT_APP_GRAPHQL_URL
});
