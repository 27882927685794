import React, { useRef } from 'react';
import styled from 'styled-components';
import get from 'lodash/get';
import { Alert } from 'antd';

export default function FormErrors(props) {
  let ref = useRef();
  let output = null;
  let error = normalizeValidationErrors(props.error);
  let hasError = !!error && !!(error.message || error.errors);

  if (hasError) {
    output = (
      <ErrorsWrapper>
        <Alert
          message={error.message}
          description={
            error.errors ? (
              <ul>
                {Object.values(error.errors).map((err, key) => (
                  <li key={key}>
                    <FieldError error={err} />
                  </li>
                ))}
              </ul>
            ) : null
          }
          type="error"
        />
      </ErrorsWrapper>
    );
  }

  return <div ref={ref}>{output}</div>;
}

function FieldError({ error }) {
  if (Array.isArray(error)) {
    return (
      <>
        {error.map((err, key) => (
          <div key={key}>{err}</div>
        ))}
      </>
    );
  }
  return error;
}

function normalizeValidationErrors(response) {
  const error = get(response, 'graphQLErrors', []);

  if (error && Array.isArray(error)) {
    let target = error.find(
      e => get(e, 'extensions.code') === 'BAD_USER_INPUT'
    );
    if (target) {
      const errors = get(target, 'extensions.exception.details', []).reduce(
        (errs, item) => ({
          ...errs,
          [item.path[0]]: item.message
        }),
        {}
      );
      return {
        message: target.message,
        errors,
        response: error
      };
    }
    target = error.find(
      e => get(e, 'extensions.code') === 'INTERNAL_SERVER_ERROR'
    );
    if (target) {
      return {
        message: target.message
      };
    }
  }

  return response;
}

const ErrorsWrapper = styled('div')`
  margin-bottom: 20px;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li:first-letter {
      text-transform: capitalize;
    }
  }
`;
