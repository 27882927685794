import { useQuery } from '@apollo/react-hooks';
import apollo from 'core/apollo';
import gql from 'graphql-tag';

export default function useJobOrderStatuses() {
  return useQuery(QUERY, getJobOrderStatusesQueryOptions());
}

export function fetchJobOrderStatuses() {
  return apollo.query(getJobOrderStatusesQueryOptions());
}

export function getJobOrderStatusesQueryOptions() {
  return {
    query: QUERY,
    fetchPolicy: 'cache-first'
  };
}

const QUERY = gql`
  query {
    result: job_order_statuses {
      id
      name
      code
      colour
      is_system
    }
  }
`;
