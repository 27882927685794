import { useQuery } from '@apollo/react-hooks';
import {
  analiseTopSellingCategories,
  analiseTopSellingProducts,
  weekNumber
} from './queries.graphql';

export function useWeekNumber() {
  return useQuery(weekNumber);
}

export function useTopSellingProducts(where, limit = 10) {
  return useQuery(analiseTopSellingProducts, {
    variables: {
      input: {
        where,
        limit
      }
    }
  });
}

export function useTopSellingCategories(where, limit = 10) {
  return useQuery(analiseTopSellingCategories, {
    variables: {
      input: {
        where,
        limit
      }
    }
  });
}
