import { useCallback, useLayoutEffect, useState } from 'react';

function createStore() {
  let listeners = {};
  let store = {};

  let notifyWatchers = (name, payload) => {
    if (name in listeners && listeners[name].length > 0) {
      for (let i = 0; i < listeners[name].length; i++) {
        listeners[name][i](payload);
      }
    }
  };

  let watch = (name, listener) => {
    if (!(name in listeners)) {
      listeners[name] = [];
    }

    if (typeof listener !== 'function') {
      throw new Error('Expected listener to be a function.');
    }

    listeners[name].push(listener);

    return () => {
      listeners[name].splice(listeners[name].indexOf(listener), 1);
    };
  };

  return {
    getData: (name, defaultValue) => {
      if (
        typeof store[name] === 'undefined' &&
        typeof defaultValue !== 'undefined'
      ) {
        store[name] = defaultValue;
      }
      return store[name];
    },
    setData: (name, value) => {
      store[name] = typeof value === 'function' ? value(store[name]) : value;
      notifyWatchers(name, store[name]);
    },
    watchData: (name, listener) => watch(name, listener)
  };
}

export let store = createStore();

export function getGlobalData(name, defaultValue) {
  return store.getData(name, defaultValue);
}

export function setGlobalData(name, value) {
  store.setData(name, value);
}

export function useGlobalData(name, defaultValue) {
  let [value, setValue] = useState(() => store.getData(name, defaultValue));
  useLayoutEffect(() => store.watchData(name, val => setValue(val)), [name]);
  return typeof value === 'undefined' ? defaultValue : value;
}

export function useGlobalState(name, defaultValue) {
  let value = useGlobalData(name, defaultValue);
  let update = useCallback(payload => setGlobalData(name, payload), [name]);
  return [value, update];
}
