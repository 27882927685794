import React from 'react';
import { ApolloProvider } from '@apollo/react-hooks';
import { ErrorBoundary } from 'react-error-boundary';
import { Router } from 'react-router-dom';
import apolloClient from 'core/apollo';
import auth from 'core/auth';
import { setGlobalData } from 'core/store';
import { history } from 'core/router';
import GlobalStyles from 'config/GlobalStyles';
import Routes from 'routes';
import NetworkOfflineOverlay from 'components/NetworkOfflineOverlay';
import ErrorFallback from './ErrorFallback';

auth.init();

// Close side navigation by default
setGlobalData('sideNavCollapsed', true);

export default function App() {
  return (
    <>
      <GlobalStyles />
      <NetworkOfflineOverlay />
      <Router history={history}>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <ApolloProvider client={apolloClient}>
            <Routes />
          </ApolloProvider>
        </ErrorBoundary>
      </Router>
    </>
  );
}
