import React from 'react';
import { Form as AntForm } from 'antd';
import FormErrors from 'components/FormErrors';
import SubmitButton from './SubmitButton';

export default function Form(props) {
  return <AntForm {...props} />;
}

Form.Errors = FormErrors;
Form.Item = AntForm.Item;
Form.Submit = Submit;

function Submit(props) {
  return <SubmitButton children="Save" {...props} />;
}
