import { useCallback, useReducer } from 'react';

const defaultInitialState = {
  data: undefined,
  error: undefined,
  failed: false,
  loaded: false,
  loading: false
};

function reducer(state, action) {
  const { payload, type } = action;

  switch (type) {
    case 'LOADING':
      return {
        ...state,
        loading: true
      };

    case 'LOADED':
      return {
        ...state,
        data: typeof payload === 'function' ? payload(state.data) : payload,
        error: undefined,
        failed: false,
        loaded: true,
        loading: false
      };

    case 'FAILED':
      return {
        ...state,
        data: undefined,
        error: payload,
        failed: true,
        loaded: false,
        loading: false
      };

    default:
      return state;
  }
}

export default function useAsyncDataReducer(initialState = {}) {
  const [state, dispatch] = useReducer(reducer, {
    ...defaultInitialState,
    ...initialState
  });

  const loading = useCallback(() => dispatch({ type: 'LOADING' }), []);

  const loaded = useCallback(
    payload => dispatch({ type: 'LOADED', payload }),
    []
  );

  const failed = useCallback(
    payload => dispatch({ type: 'FAILED', payload }),
    []
  );

  return {
    ...state,
    dispatch,
    actions: {
      failed,
      loaded,
      loading
    }
  };
}
