import React from 'react';
import get from 'lodash/get';
import { Card, Col, Row } from 'antd';
import Form from 'ui/Form';
import TextInput from 'components/input/TextInput';
import FullAddressInput from 'components/input/FullAddressInput';
import classes from '../../../components/coupons/steps/styles.module.css';

export default function Step3({ form, errors }) {
  const getErrorPropsFor = name => {
    const err = get(errors, name);
    if (err) {
      return {
        validateStatus: 'error',
        help: err
      };
    }
  };

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <Card className={classes.card} title="3. Store Details">
            <Form.Item label="Store Name" {...getErrorPropsFor('store_name')}>
              <TextInput
                placeholder="Store Name"
                {...form.getInputPropsFor('store_name')}
              />
            </Form.Item>

            <Form.Item
              label="Store Address"
              {...getErrorPropsFor('store_address')}
            >
              <FullAddressInput {...form.getInputPropsFor('store_address')} />
            </Form.Item>
          </Card>
        </Col>
      </Row>
    </>
  );
}
