import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { Icon } from 'antd';
import useIsOnline from 'hooks/useIsOnline';

let Wrapper = styled('div')`
  align-items: center;
  background-color: rgba(255, 255, 255, 0.9);
  bottom: 0;
  color: #999;
  display: flex;
  flex-direction: row;
  font-size: 18px;
  //height: 100%;
  justify-content: center;
  left: 0;
  padding: 16px;
  position: fixed;
  //top: 0;
  width: 100%;

  .anticon {
    font-size: 40px;
  }

  p {
    margin: 0 0 0 30px;
  }
`;

export default function NetworkOfflineOverlay() {
  const [container, setContainer] = useState();
  const isOnline = useIsOnline();

  useEffect(() => {
    if (!isOnline) {
      let node = document.createElement('div');
      node.id = 'network-offline-status';
      document.body.appendChild(node);
      setContainer(node);
    } else {
      let node = document.getElementById('network-offline-status');
      if (node) {
        node.parentNode.removeChild(node);
        setContainer(null);
      }
    }
  }, [isOnline]);

  if (container) {
    return ReactDOM.createPortal(
      <Wrapper>
        <Icon type="disconnect" />
        <p>
          You're offline!
          <br />
          <small>Please get back online in order to use the app.</small>
        </p>
      </Wrapper>,
      container
    );
  }

  return null;
}
