import { useMemo } from 'react';
import get from 'lodash/get';
import { useGlobalData } from 'core/store';

export function useCartData() {
  const cartFromStore = useGlobalData('cart');
  const notifySuppliers = get(cartFromStore, 'notifySuppliers', false);
  const reference = get(cartFromStore, 'reference', '');
  const items = get(cartFromStore, 'items', []);
  const parentId = get(cartFromStore, 'parent_purchase_order_id');
  return useMemo(
    () => ({
      parentId,
      items,
      notifySuppliers,
      reference
    }),
    [reference, items, notifySuppliers, parentId]
  );
}
