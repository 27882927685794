import React from 'react';
import get from 'lodash/get';
import styled from 'styled-components';
import packageJson from './../../../package.json';

const Wrapper = styled('footer')`
  line-height: 20px;
  padding: 20px;
  text-align: center;
`;

export default function Footer() {
  const pkgVersion = get(packageJson, 'version', 0);
  return <Wrapper>App v{pkgVersion} Installed</Wrapper>;
}
