import React, { Children, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { PageHeader, Skeleton, Result } from 'antd';
import { breakpoints } from 'config/antd';
import Button from 'ui/Button';
import { useIsMobileScreen } from 'hooks/screen';

export default function Page({ bg, children, loading, notFound, width }) {
  let isMobile = useIsMobileScreen();

  useEffect(() => {
    document.body.style.backgroundColor = bg || '#fff';
  }, [bg]);

  let nav;
  let other = [];

  Children.forEach(children, child => {
    if (child?.type === Nav) {
      nav = child;
    } else {
      other.push(child);
    }
  });

  let content;

  if (loading) {
    content = <Skeleton />;
  } else if (notFound) {
    content = (
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you're looking for is not found."
        extra={
          <Button to="/" type="primary">
            Back to Dashboard
          </Button>
        }
      />
    );
  } else {
    content = other;
  }

  let style = {};
  let output;

  if (nav) {
    if (isMobile) {
      output = (
        <>
          {nav}
          <ContentWrapper>{content}</ContentWrapper>
        </>
      );
    } else {
      if (width === 'small') {
        style.maxWidth = 900;
      } else if (width === 'medium') {
        style.maxWidth = 1100;
      } else if (width === 'large') {
        style.maxWidth = 1100;
      }
      output = (
        <WrapperWithNav>
          <div className="nav-wrapper">{nav}</div>
          <div className="main-wrapper">
            <ContentWrapper>{content}</ContentWrapper>
          </div>
        </WrapperWithNav>
      );
    }
  } else {
    if (width === 'small') {
      style.maxWidth = 768;
    } else if (width === 'medium') {
      style.maxWidth = 900;
    } else if (width === 'large') {
      style.maxWidth = 1100;
    }
    output = <ContentWrapper>{content}</ContentWrapper>;
  }

  return <Wrapper style={style}>{output}</Wrapper>;
}

function Header({ backTo, title, ...rest }) {
  let history = useHistory();

  useEffect(() => {
    document.title = `${title} - ShoppyCommerce`;
  }, [title]);

  let getOnBack = () => {
    if (backTo) {
      if (typeof backTo === 'function') {
        return () => backTo();
      } else if (typeof backTo === 'string') {
        return () => history.push(backTo);
      } else if (history.length > 0) {
        return () => history.goBack();
      }
    }
  };

  return (
    <div className="page-header-wrapper">
      <PageHeader title={title} onBack={getOnBack()} {...rest} />
    </div>
  );
}

let Content = styled('div')``;

let WrapperWithNav = styled('div')`
  display: flex;

  .nav-wrapper {
    border-right: 1px solid #f5f5f5;
    width: 200px;
  }

  .main-wrapper {
    flex-grow: 1;
    max-width: calc(100% - 200px);
  }
`;

let Nav = styled('div')`
  min-height: 100%;
  padding: 24px 0;

  .ant-menu-inline {
    border-right: none !important;
  }
`;

let ContentWrapper = styled('div')`
  padding: 16px;

  @media (min-width: ${breakpoints.md}px) {
    padding: 32px;
  }
`;

let Wrapper = styled('div')`
  margin: 0 auto;

  .page-header-wrapper {
    margin: 0 0 32px;

    .ant-page-header {
      padding: 0;

      .ant-page-header-heading-title {
        font-size: 18px;
        font-weight: normal;
      }

      .ant-page-header-heading-extra {
        right: 0;
        top: 0;
      }
    }
  }
`;

Page.Nav = Nav;
Page.Header = Header;
Page.Content = Content;
