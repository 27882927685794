import React from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import { Modal } from 'antd';
import Page from 'components/page';
import Form, { validator } from 'components/form';
import Brand from 'components/form/BrandField';
import Buyer from 'components/form/BuyerField';
import Category from 'components/form/CategoryField';
import Product from 'components/form/ProductField';
import Supplier from 'components/form/SupplierField';
import { useCurrentUser } from 'hooks/user';
import { formatDateForApi } from 'utils/format';
import { emailProductSalesForecast as forecastQuery } from './api/queries.graphql';

const validate = validator({
  reportDate: 'required'
});

export default function ExportSalesForecast() {
  const user = useCurrentUser();
  const apollo = useApolloClient();

  const handleSubmit = async data => {
    try {
      await apollo.query({
        query: forecastQuery,
        variables: {
          where: {
            brandIds: data.brandIds || [],
            buyerIds: data.buyerIds || [],
            categoryIds: data.categoryIds || [],
            productIds: data.productIds || [],
            reportDate: formatDateForApi(data.reportDate),
            supplierIds: data.supplierIds || []
          }
        }
      });
      Modal.success({
        title: 'The request is being processed.',
        content: 'Generated forecasts will be sent to buyers’ email address'
      });
    } catch (error) {
      throw error;
    }
  };

  return (
    <Page>
      <Page.Header title="Export Sales Forecast">
        Regenerate your purchase orders by selecting the options below
      </Page.Header>

      <Page.Content maxWidth={600}>
        <Form onSubmit={handleSubmit} validate={validate}>
          <Form.Errors />

          <Form.Item label="Report Date">
            <Form.DatePicker name="reportDate" />
          </Form.Item>

          <Form.Item label="Suppliers">
            <Supplier name="supplierIds" mode="multiple" />
          </Form.Item>

          <Form.Item label="Brands">
            <Brand name="brandIds" mode="multiple" />
          </Form.Item>

          {user.is_admin && (
            <Form.Item label="Buyers">
              <Buyer name="buyerIds" mode="multiple" />
            </Form.Item>
          )}

          <Form.Item label="Categories">
            <Category name="categoryIds" mode="multiple" />
          </Form.Item>

          <Form.Item label="Products">
            <Product name="productIds" mode="multiple" />
          </Form.Item>

          <Form.Item>
            <Form.Submit>Export</Form.Submit>
          </Form.Item>
        </Form>
      </Page.Content>
    </Page>
  );
}
