import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { Link, useHistory } from 'react-router-dom';
import { Affix, Layout as AntLayout } from 'antd';
import { setGlobalData, useGlobalData } from 'core/store';
import { useIsMobileScreen } from 'hooks/screen';
import Logo from 'images/ShoppyCommerceERP.svg';
import BigLogo from 'images/Shoppywhite.svg';
import Footer from './Footer';
import SideNav from './SideNav';
import TopNav from './TopNav';
import Suspense from './Suspense';

let collapsedSize = 80;

const Mask = styled('div')`
  background-color: rgba(0, 0, 0, 0.3);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 99;
`;

const MainContainer = styled('div')`
  margin: 0 auto;
  min-height: calc(100vh - 120px); // Sum of TopNav and Footer height
`;

const Wrapper = styled('div')`
  position: relative;

  > .ant-layout {
    background-color: transparent;
    min-height: 100vh;

    > aside {
      @media (min-width: 769px) {
        flex: ${props => (props.isCollapsed ? '0 0 100px !important' : '')};
        min-width: ${props => (props.isCollapsed ? '100px !important' : '')};
        max-width: ${props => (props.isCollapsed ? '100px !important' : '')};
        width: ${props => (props.isCollapsed ? '100px !important' : '')};
      }

      .side-content {
        height: 100vh;
        overflow-y: auto;
        position: relative;

        &::-webkit-scrollbar {
          background-color: transparent;
          width: 5px;
        }

        &::-webkit-scrollbar-track {
          background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #babac0;
          border-radius: 16px;
        }

        &::-webkit-scrollbar-button {
          display:none;
        }

        .side-logo {
          background-image: url("${props =>
            props.isCollapsed ? Logo : BigLogo}");
          background-repeat: no-repeat;
          background-position-x: ${props =>
            props.isCollapsed ? 'left' : '3px'};
          background-position-y: center;
          background-size: ${props =>
            props.isCollapsed ? 'contain' : 'cover'};
          height: 55px;
          width: ${props => (props.isCollapsed ? '50%' : 'auto')};
          margin: ${props => (props.isCollapsed ? '8px auto' : '8px 10px')};
        }
      }
    }

    .ant-layout-content {
      background-color: transparent;
      position: relative;
    }

    > .ant-layout {
      position: relative;

      .ant-layout-header {
        position: sticky;
        top: 0;
      }
    }
  }
`;

export default function ReportsLayout({ children }) {
  const history = useHistory();
  const isMobile = useIsMobileScreen();
  const collapsed = useGlobalData('sideNavCollapsed');

  // Listen to history changes
  useEffect(() => {
    history.listen(async (location, action) => {
      // check for sw updates on page change
      if ('serviceWorker' in navigator) {
        console.log('Checking for updates in SW');
        await navigator.serviceWorker
          .getRegistrations()
          .then(regs => regs.forEach(reg => reg.update()));
      }
    });

    return () => {};
  }, [history]);

  const siderStyle = useMemo(
    () =>
      isMobile
        ? {
            height: '100vh',
            left: 0,
            position: 'fixed',
            top: 0,
            zIndex: 100
          }
        : undefined,
    [isMobile]
  );

  const handleCollapse = () => {
    setGlobalData('sideNavCollapsed', prev => !prev);
  };

  const handleClose = () => {
    if (/*isMobile && */ !collapsed) {
      setGlobalData('sideNavCollapsed', true);
    }
  };

  return (
    <Wrapper isCollapsed={collapsed}>
      <AntLayout>
        {!collapsed && isMobile && <Mask onClick={handleClose} />}
        <AntLayout.Sider
          collapsible={true}
          collapsed={collapsed}
          collapsedWidth={isMobile ? 0 : collapsedSize}
          onCollapse={handleCollapse}
          style={siderStyle}
          trigger={null}
          width={220}
        >
          <Affix offsetTop={0}>
            <div className="side-content">
              <Link to="/">
                <div className="side-logo" />
              </Link>
              <SideNav collapsed={collapsed} onNavigate={handleClose} />
            </div>
          </Affix>
        </AntLayout.Sider>

        <AntLayout.Content>
          <TopNav />
          <MainContainer>
            <Suspense>{children}</Suspense>
          </MainContainer>
          <Footer />
        </AntLayout.Content>
      </AntLayout>
    </Wrapper>
  );
}
