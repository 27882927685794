import React from 'react';
import ReactDOM from 'react-dom';
import get from 'lodash/get';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { Icon } from 'antd';
import auth from 'core/auth';
import App from 'core/App';
import prefetchGlobalQueries from 'api/prefetchGlobalQueries';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import 'config/modifyAntDesign';
import { ReactComponent as Logo } from 'images/shoppycommerce-logo-sign.svg';
import pkg from '../package.json';
import classes from './index.module.css';
import env from './config/env';

window.FreshworksWidget('hide', 'launcher');

const VERSION = get(pkg, 'version', 0);

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://ea7ae7e43d0141b08e2410e405bc6250@sentry.io/879453',
    release: `ShoppyERP-v${VERSION}`,
    autoSessionTracking: true,
    integrations: [new Integrations.BrowserTracing()],
    // We recommend adjusting this value in production, or using tracesSampler for finer control
    tracesSampleRate: 1.0
  });
}

async function prefetch() {
  const isAuthenticated = await auth.isAuthenticated();
  if (isAuthenticated) {
    prefetchGlobalQueries();
  }
}

prefetch();

auth.init().then(user => {
  if (user && env('NODE_ENV') === 'production') {
    Sentry.setUser({
      id: user.id,
      email: user.email
    });
  }
});

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorkerRegistration.register({
  onUpdate(registration) {
    let waitingServiceWorker = registration.waiting;

    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener('statechange', event => {
        if (event.target.state === 'activated') {
          window.location.reload();
        }
      });

      const modal = (
        <div className={classes.updating}>
          <div>
            <Logo className={classes.logo} />
            <p>
              <Icon type="loading" />
              &nbsp;&nbsp;&nbsp;&nbsp; We are updating you to the latest &
              greatest...
            </p>
          </div>
        </div>
      );

      const target = document.createElement('div');

      ReactDOM.render(modal, target);
      document.body.appendChild(target);
      waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
    }
  }
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
