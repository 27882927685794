import React from 'react';
import { AutoComplete } from 'antd';
import useAsyncDataReducer from 'hooks/useAsyncDataReducer';
import useDebounceCallback from 'hooks/useDebounceCallback';

export default function AutoCompleteInput(props) {
  let { dataSource, initialOptions, onSearch, ...inputProps } = props;

  let { actions, data: options, loading } = useAsyncDataReducer({
    data: initialOptions || []
  });

  let handleSearch = useDebounceCallback(
    async query => {
      if (typeof onSearch === 'function') {
        actions.loading();
        let result = await onSearch(query);
        actions.loaded(result || []);
      }
    },
    [onSearch]
  );

  const data = dataSource || options || [];
  const uniqueData = data.filter((item, index) => data.indexOf(item) === index);

  return (
    <AutoComplete
      dropdownMatchSelectWidth={false}
      {...inputProps}
      dataSource={uniqueData}
      loading={loading}
      onSearch={handleSearch}
    />
  );
}
