import React from 'react';
import { Result } from 'antd';
import Button from 'ui/Button';

export default function Step4() {
  return (
    <>
      <Result
        status="success"
        title="Successfully created your company."
        subTitle="Use below link to login into your ERP."
        extra={[
          <Button to="/login" type="primary" key="login">
            Login
          </Button>
        ]}
      />
    </>
  );
}
