import React, { useMemo } from 'react';
import styled from 'styled-components';
import get from 'lodash/get';
import useStores from 'api/store/useStores';
import { sortAlphabetically } from 'utils/data';
import badge from 'images/integrations/magento-badge.png';
import SelectInput from './SelectInput';

export default function StoreInput(props) {
  const {
    allowSelectAll,
    allowSelectAllOnEmpty,
    disabled,
    excludeIds,
    mode,
    onChange,
    placeholder,
    value,
    ...rest
  } = props;

  let { data, loading } = useStores();

  let stores = useMemo(() => sortAlphabetically(get(data, 'result', [])), [
    data
  ]);

  if (Array.isArray(excludeIds)) {
    stores = stores.filter(store => !excludeIds.includes(store.id));
  }

  let handleSelect = val => {
    if (allowSelectAll && val === '__ALL__') {
      onChange(stores.map(store => store.id));
    }
  };

  let finalDisabled = disabled || loading;
  let finalPlaceholder = loading ? 'Loading...' : placeholder;
  let finalValue = loading ? undefined : value;

  return (
    <SelectInput
      {...rest}
      disabled={finalDisabled}
      filterOption={filterOption}
      loading={loading}
      mode={mode}
      onChange={onChange}
      onSelect={handleSelect}
      optionLabelProp="label"
      placeholder={finalPlaceholder}
      value={finalValue}
      showSearch
    >
      {mode === 'multiple' && stores.length > 0 && allowSelectAll && (
        <SelectInput.Option value="__ALL__">
          Select all stores
        </SelectInput.Option>
      )}
      {allowSelectAllOnEmpty && (
        <SelectInput.Option value="">All stores</SelectInput.Option>
      )}
      {stores.map(store => (
        <SelectInput.Option
          key={store.id}
          code={store.store_code}
          label={store.name}
          value={store.id}
        >
          <OptionWrapper>
            <span>{store.name}</span>
            {get(store, 'magento_integrations', []).length > 0 && (
              <img alt="Magento Integration" src={badge} />
            )}
            <br />
            <small>Code: {store.store_code}</small>
          </OptionWrapper>
        </SelectInput.Option>
      ))}
    </SelectInput>
  );
}

StoreInput.defaultProps = {
  allowClear: true,
  allowSelectAll: false,
  allowSelectAllOnEmpty: false
};

function filterOption(input, option) {
  let { code, label } = option.props;
  if (code && label) {
    return (
      label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
      String(code).indexOf(input.toLowerCase()) >= 0
    );
  }
  return false;
}

let OptionWrapper = styled('div')`
  //align-items: center;
  //display: flex;
  //justify-content: space-between;

  img {
    height: 16px;
    margin-left: 8px;
    vertical-align: middle;
    width: 16px;
  }
`;
