import React, { useState } from 'react';
import { Steps } from 'antd';
import { fetchWrapper } from 'api/fetch';
import useSteps from 'hooks/useSteps';
import useMiniForm from 'hooks/useMiniForm';
import Page from 'ui/Page';
import Button from 'ui/Button';
import Modal from 'ui/Modal';
import AffixButtons from 'components/affix-buttons';
import { ReactComponent as Logo } from 'images/shoppycommerce-logo.svg';
import Step1 from './components/Step1';
import Step2 from './components/Step2';
import Step3 from './components/Step3';
import Step4 from './components/Step4';
import validate from './utils/validation';
import classes from './index.module.css';

const initialValues = {
  company_currency: 'United States'
};

export default function Onboarding() {
  const [step, nextStep, prevStep] = useSteps();
  const [errors, setErrors] = useState({});
  const form = useMiniForm({
    initialValues,
    onSubmit: async values => {
      const errs = validate(values);
      if (errs.step3) {
        setErrors(errs);
        return;
      }
      setErrors({});

      try {
        const result = await fetchWrapper.post('on-board/register', {
          firstName: values.first_name,
          lastName: values.last_name,
          email: values.email,
          timeZone: values.company_timezone,
          companyName: values.company_name,
          companyCurrency: values.company_currency,
          companyAddress: values.company_address,
          storeName: values.store_name,
          storeAddress: values.store_address
        });
        nextStep();
      } catch (error) {
        Modal.error({
          title: error
        });
      }
    }
  });

  const onNextStep = () => {
    const errs = validate(form.values);
    switch (step) {
      case 0:
        if (errs.step1) {
          setErrors(errs);
          return;
        }
        setErrors({});
        nextStep();
        break;
      case 1:
        if (errs.step2) {
          setErrors(errs);
          return;
        }
        setErrors({});
        nextStep();
        break;
      default:
        break;
    }
  };

  const extra = (
    <>
      {step > 0 && step < 3 && <Button onClick={prevStep}>Back</Button>}
      {step === 2 && (
        <Button onClick={form.submit} loading={form.submitting} type="primary">
          Create
        </Button>
      )}
      {step <= 1 && (
        <Button onClick={onNextStep} type="primary">
          Continue
        </Button>
      )}
    </>
  );

  return (
    <>
      <Page width="small">
        <div className={classes.wrapper}>
          <div className={classes.logo}>
            <Logo />
          </div>
        </div>
        <Page.Header title="Setup your company" extra={extra} />

        <Page.Content>
          <Steps current={step} size="small">
            <Steps.Step title="User Details" />
            <Steps.Step title="Company Details" />
            <Steps.Step title="Store Details" />
            <Steps.Step title="Result" />
          </Steps>

          <div className={classes.content}>
            {step === 0 && <Step1 errors={errors} form={form} />}
            {step === 1 && <Step2 errors={errors} form={form} />}
            {step === 2 && <Step3 errors={errors} form={form} />}
            {step === 3 && <Step4 />}
          </div>

          <AffixButtons>
            {step > 0 && step < 3 && <Button onClick={prevStep}>Back</Button>}
            {step === 2 && (
              <Button
                onClick={form.submit}
                loading={form.submitting}
                type="primary"
              >
                Create
              </Button>
            )}
            {step <= 1 && (
              <Button onClick={onNextStep} type="primary">
                Continue
              </Button>
            )}
          </AffixButtons>
        </Page.Content>
      </Page>
    </>
  );
}
