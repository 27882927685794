import { useEffect } from 'react';
import auth from 'core/auth';

export default function Login() {
  const login = async () => {
    const isAuthenticated = await auth.isAuthenticated();

    if (isAuthenticated) {
      window.location.href = '/';
    } else {
      await auth.login();
    }
  };
  useEffect(() => {
    login();
  }, []);

  return null;
}

export function Callback() {
  const initial = async () => {
    await auth.authenticate();
  };
  useEffect(() => {
    initial();
  }, []);

  return null;
}
