import React from 'react';
import { Alert, Button, Table } from 'antd';
import SuccessButton from 'ui/SuccessButton';
import cart from 'utils/cart';
import ProductInfo from './ProductInfo';

export default function Cart({ cartData, onClose }) {
  return (
    <>
      {cartData.items.length === 0 && (
        <Alert message="Cart is empty!" type="info" />
      )}
      {cartData.items.length > 0 && (
        <CartItems cartData={cartData} onClose={onClose} />
      )}
    </>
  );
}

function CartItems({ cartData, onClose }) {
  let handleClearCart = () => {
    cart().truncate();
    onClose();
  };

  return (
    <>
      <div style={{ textAlign: 'right' }}>
        <Button
          icon="delete"
          onClick={handleClearCart}
          size="small"
          shape="round"
        >
          Clear Cart
        </Button>
      </div>
      <br />
      <Table columns={columns} dataSource={cartData.items} pagination={false} />
      <br />
      <SuccessButton
        onClick={onClose}
        size="large"
        to="/purchase-orders/create/manual"
        block
      >
        Create Purchase Order
      </SuccessButton>
    </>
  );
}

let columns = [
  {
    key: 'product',
    render: record => (
      <ProductInfo id={record.product_id} qty={record.quantity} />
    ),
    title: 'Product'
  },
  {
    key: 'delete',
    render: data => (
      <Button
        icon="delete"
        onClick={() => cart().remove(data.key)}
        size="small"
      />
    )
  }
];
