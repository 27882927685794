const currencies = [
  {
    name: 'Afghanistan',
    currency: 'AFN',
    symbol: '؋'
  },
  {
    name: 'Albania',
    currency: 'ALL',
    symbol: 'L'
  },
  {
    name: 'Algeria',
    currency: 'DZD',
    symbol: 'دج'
  },
  {
    name: 'American Samoa',
    currency: 'USD',
    symbol: '$'
  },
  {
    name: 'Andorra',
    currency: 'EUR',
    symbol: '€'
  },
  {
    name: 'Angola',
    currency: '',
    symbol: ''
  },
  {
    name: 'Anguilla',
    currency: 'XCD',
    symbol: '$'
  },
  {
    name: 'Antigua and Barbuda',
    currency: 'XCD',
    symbol: '$'
  },
  {
    name: 'Argentina',
    currency: 'ARS',
    symbol: '$'
  },
  {
    name: 'Armenia',
    currency: 'AMD',
    symbol: '֏'
  },
  {
    name: 'Aruba',
    currency: 'AWG',
    symbol: 'ƒ'
  },
  {
    name: 'Australia',
    currency: 'AUD',
    symbol: '$'
  },
  {
    name: 'Austria',
    currency: 'EUR',
    symbol: '€'
  },
  {
    name: 'Azerbaijan',
    currency: 'AZN',
    symbol: '₼'
  },
  {
    name: 'Bahamas',
    currency: 'BSD',
    symbol: '$'
  },
  {
    name: 'Bahrain',
    currency: 'BHD',
    symbol: '.د.ب'
  },
  {
    name: 'Bangladesh',
    currency: 'BDT',
    symbol: '৳'
  },
  {
    name: 'Barbados',
    currency: 'BBD',
    symbol: '$'
  },
  {
    name: 'Belarus',
    currency: 'BYN',
    symbol: 'Br'
  },
  {
    name: 'Belgium',
    currency: 'EUR',
    symbol: '€'
  },
  {
    name: 'Belize',
    currency: 'BZD',
    symbol: 'BZ$'
  },
  {
    name: 'Benin',
    currency: 'XOF',
    symbol: 'CFA'
  },
  {
    name: 'Bermuda',
    currency: 'BMD',
    symbol: '$'
  },
  {
    name: 'Bhutan',
    currency: 'BTN',
    symbol: 'Nu.'
  },
  {
    name: 'Bolivia, Plurinational State of',
    currency: 'BOB',
    symbol: '$b'
  },
  {
    name: 'Bosnia and Herzegovina',
    currency: 'BAM',
    symbol: 'KM'
  },
  {
    name: 'Botswana',
    currency: 'BWP',
    symbol: 'P'
  },
  {
    name: 'Brazil',
    currency: 'BRL',
    symbol: 'R$'
  },
  {
    name: 'British Indian Ocean Territory',
    currency: 'USD',
    symbol: '$'
  },
  {
    name: 'Bulgaria',
    currency: 'BGN',
    symbol: 'лв'
  },
  {
    name: 'Burkina Faso',
    currency: 'XOF',
    symbol: 'CFA'
  },
  {
    name: 'Burundi',
    currency: 'BIF',
    symbol: 'FBu'
  },
  {
    name: 'Cambodia',
    currency: 'KHR',
    symbol: '៛'
  },
  {
    name: 'Cameroon',
    currency: 'XAF',
    symbol: 'FCFA'
  },
  {
    name: 'Canada',
    currency: 'CAD',
    symbol: '$'
  },
  {
    name: 'Cape Verde',
    currency: 'CVE',
    symbol: '$'
  },
  {
    name: 'Cayman Islands',
    currency: 'KYD',
    symbol: '$'
  },
  {
    name: 'Central African Republic',
    currency: 'XAF',
    symbol: 'FCFA'
  },
  {
    name: 'Chad',
    currency: 'XAF',
    symbol: 'FCFA'
  },
  {
    name: 'Chile',
    currency: 'CLP',
    symbol: '$'
  },
  {
    name: 'China',
    currency: 'CNY',
    symbol: '¥'
  },
  {
    name: 'Colombia',
    currency: 'COP',
    symbol: '$'
  },
  {
    name: 'Comoros',
    currency: 'KMF',
    symbol: 'CF'
  },
  {
    name: 'Congo',
    currency: 'XAF',
    symbol: 'FCFA'
  },
  {
    name: 'Democratic Republic of the Congo',
    currency: 'CDF',
    symbol: 'FC'
  },
  {
    name: 'Cook Islands',
    currency: 'NZD',
    symbol: '$'
  },
  {
    name: 'Costa Rica',
    currency: 'CRC',
    symbol: '₡'
  },
  {
    name: "Côte d'Ivoire",
    currency: 'XOF',
    symbol: 'CFA'
  },
  {
    name: 'Croatia',
    currency: 'HRK',
    symbol: 'kn'
  },
  {
    name: 'Cuba',
    currency: 'CUP',
    symbol: '₱'
  },
  {
    name: 'Curaçao',
    currency: 'ANG',
    symbol: 'ƒ'
  },
  {
    name: 'Cyprus',
    currency: 'EUR',
    symbol: '€'
  },
  {
    name: 'Czech Republic',
    currency: 'CZK',
    symbol: 'Kč'
  },
  {
    name: 'Denmark',
    currency: 'DKK',
    symbol: 'kr'
  },
  {
    name: 'Djibouti',
    currency: 'DJF',
    symbol: 'Fdj'
  },
  {
    name: 'Dominica',
    currency: 'XCD',
    symbol: '$'
  },
  {
    name: 'Dominican Republic',
    currency: 'DOP',
    symbol: 'RD$'
  },
  {
    name: 'Ecuador',
    currency: 'USD',
    symbol: '$'
  },
  {
    name: 'Egypt',
    currency: 'EGP',
    symbol: '£'
  },
  {
    name: 'El Salvador',
    currency: 'SVC',
    symbol: '$'
  },
  {
    name: 'Equatorial Guinea',
    currency: 'XAF',
    symbol: 'FCFA'
  },
  {
    name: 'Eritrea',
    currency: 'ERN',
    symbol: 'Nfk'
  },
  {
    name: 'Estonia',
    currency: 'EUR',
    symbol: '€'
  },
  {
    name: 'Ethiopia',
    currency: 'ETB',
    symbol: 'Br'
  },
  {
    name: 'Falkland Islands (Malvinas)',
    currency: 'FKP',
    symbol: '£'
  },
  {
    name: 'Faroe Islands',
    currency: 'DKK',
    symbol: 'kr'
  },
  {
    name: 'Fiji',
    currency: 'FJD',
    symbol: '$'
  },
  {
    name: 'Finland',
    currency: 'EUR',
    symbol: '€'
  },
  {
    name: 'France',
    currency: 'EUR',
    symbol: '€'
  },
  {
    name: 'French Polynesia',
    currency: 'XPF',
    symbol: '₣'
  },
  {
    name: 'Gabon',
    currency: 'XAF',
    symbol: 'FCFA'
  },
  {
    name: 'Gambia',
    currency: 'GMD',
    symbol: 'D'
  },
  {
    name: 'Georgia',
    currency: 'GEL',
    symbol: '₾'
  },
  {
    name: 'Germany',
    currency: 'EUR',
    symbol: '€'
  },
  {
    name: 'Ghana',
    currency: 'GHS',
    symbol: 'GH₵'
  },
  {
    name: 'Gibraltar',
    currency: 'GIP',
    symbol: '£'
  },
  {
    name: 'Greece',
    currency: 'EUR',
    symbol: '€'
  },
  {
    name: 'Greenland',
    currency: 'DKK',
    symbol: 'kr'
  },
  {
    name: 'Grenada',
    currency: 'XCD',
    symbol: '$'
  },
  {
    name: 'Guam',
    currency: 'USD',
    symbol: '$'
  },
  {
    name: 'Guatemala',
    currency: 'GTQ',
    symbol: 'Q'
  },
  {
    name: 'Guernsey',
    currency: 'GBP',
    symbol: '£'
  },
  {
    name: 'Guinea',
    currency: 'GNF',
    symbol: 'FG'
  },
  {
    name: 'Guinea-Bissau',
    currency: 'XOF',
    symbol: 'CFA'
  },
  {
    name: 'Haiti',
    currency: 'HTG',
    symbol: 'G'
  },
  {
    name: 'Honduras',
    currency: 'HNL',
    symbol: 'L'
  },
  {
    name: 'Hong Kong',
    currency: 'HKD',
    symbol: '$'
  },
  {
    name: 'Hungary',
    currency: 'HUF',
    symbol: 'Ft'
  },
  {
    name: 'Iceland',
    currency: 'ISK',
    symbol: 'kr'
  },
  {
    name: 'India',
    currency: 'INR',
    symbol: '₹'
  },
  {
    name: 'Indonesia',
    currency: 'IDR',
    symbol: 'Rp'
  },
  {
    name: 'Iran, Islamic Republic of',
    currency: 'IRR',
    symbol: '﷼'
  },
  {
    name: 'Iraq',
    currency: 'IQD',
    symbol: 'ع.د'
  },
  {
    name: 'Ireland',
    currency: 'EUR',
    symbol: '€'
  },
  {
    name: 'Isle of Man',
    currency: 'GBP',
    symbol: '£'
  },
  {
    name: 'Israel',
    currency: 'ILS',
    symbol: '₪'
  },
  {
    name: 'Italy',
    currency: 'EUR',
    symbol: '€'
  },
  {
    name: 'Jamaica',
    currency: 'JMD',
    symbol: 'J$'
  },
  {
    name: 'Japan',
    currency: 'JPY',
    symbol: '¥'
  },
  {
    name: 'Jersey',
    currency: 'GBP',
    symbol: '£'
  },
  {
    name: 'Jordan',
    currency: 'JOD',
    symbol: 'JD'
  },
  {
    name: 'Kazakhstan',
    currency: 'KZT',
    symbol: 'лв'
  },
  {
    name: 'Kenya',
    currency: 'KES',
    symbol: 'KSh'
  },
  {
    name: 'Kiribati',
    currency: 'AUD',
    symbol: '$'
  },
  {
    name: 'North Korea',
    currency: 'KPW',
    symbol: '₩'
  },
  {
    name: 'South Korea',
    currency: 'KRW',
    symbol: '₩'
  },
  {
    name: 'Kuwait',
    currency: 'KWD',
    symbol: 'KD'
  },
  {
    name: 'Kyrgyzstan',
    currency: 'KGS',
    symbol: 'лв'
  },
  {
    name: "Lao People's Democratic Republic",
    currency: 'LAK',
    symbol: '₭'
  },
  {
    name: 'Latvia',
    currency: 'LVL',
    symbol: 'Ls'
  },
  {
    name: 'Lebanon',
    currency: 'LBP',
    symbol: '£'
  },
  {
    name: 'Lesotho',
    currency: 'LSL',
    symbol: 'M'
  },
  {
    name: 'Liberia',
    currency: 'LRD',
    symbol: '$'
  },
  {
    name: 'Libya',
    currency: 'LYD',
    symbol: 'LD'
  },
  {
    name: 'Liechtenstein',
    currency: 'CHF',
    symbol: 'CHF'
  },
  {
    name: 'Lithuania',
    currency: 'LTL',
    symbol: 'Lt'
  },
  {
    name: 'Luxembourg',
    currency: 'EUR',
    symbol: '€'
  },
  {
    name: 'Macao',
    currency: 'MOP',
    symbol: 'MOP$'
  },
  {
    name: 'Republic of Macedonia',
    currency: 'MKD',
    symbol: 'ден'
  },
  {
    name: 'Madagascar',
    currency: 'MGA',
    symbol: 'Ar'
  },
  {
    name: 'Malawi',
    currency: 'MWK',
    symbol: 'MK'
  },
  {
    name: 'Malaysia',
    currency: 'MYR',
    symbol: 'RM'
  },
  {
    name: 'Maldives',
    currency: 'MVR',
    symbol: 'Rf'
  },
  {
    name: 'Mali',
    currency: 'XOF',
    symbol: 'CFA'
  },
  {
    name: 'Malta',
    currency: 'EUR',
    symbol: '€'
  },
  {
    name: 'Marshall Islands',
    currency: 'USD',
    symbol: '$'
  },
  {
    name: 'Martinique',
    currency: 'EUR',
    symbol: '€'
  },
  {
    name: 'Mauritania',
    currency: 'MRO',
    symbol: 'UM'
  },
  {
    name: 'Mauritius',
    currency: 'MUR',
    symbol: '₨'
  },
  {
    name: 'Mexico',
    currency: 'MXN',
    symbol: '$'
  },
  {
    name: 'Micronesia, Federated States of',
    currency: 'USD',
    symbol: '$'
  },
  {
    name: 'Republic of Moldova',
    currency: 'MDL',
    symbol: 'lei'
  },
  {
    name: 'Monaco',
    currency: 'EUR',
    symbol: '€'
  },
  {
    name: 'Mongolia',
    currency: 'MNT',
    symbol: '₮'
  },
  {
    name: 'Montenegro',
    currency: 'EUR',
    symbol: '€'
  },
  {
    name: 'Montserrat',
    currency: 'XCD',
    symbol: '$'
  },
  {
    name: 'Morocco',
    currency: 'MAD',
    symbol: 'MAD'
  },
  {
    name: 'Mozambique',
    currency: 'MZN',
    symbol: 'MT'
  },
  {
    name: 'Myanmar',
    currency: 'MMK',
    symbol: 'K'
  },
  {
    name: 'Namibia',
    currency: 'NAD',
    symbol: '$'
  },
  {
    name: 'Nauru',
    currency: 'AUD',
    symbol: '$'
  },
  {
    name: 'Nepal',
    currency: 'NPR',
    symbol: '₨'
  },
  {
    name: 'Netherlands',
    currency: 'EUR',
    symbol: '€'
  },
  {
    name: 'New Zealand',
    currency: 'NZD',
    symbol: '$'
  },
  {
    name: 'Nicaragua',
    currency: 'NIO',
    symbol: 'C$'
  },
  {
    name: 'Niger',
    currency: 'XOF',
    symbol: 'CFA'
  },
  {
    name: 'Nigeria',
    currency: 'NGN',
    symbol: '₦'
  },
  {
    name: 'Niue',
    currency: 'NZD',
    symbol: '$'
  },
  {
    name: 'Norfolk Island',
    currency: 'AUD',
    symbol: '$'
  },
  {
    name: 'Northern Mariana Islands',
    currency: 'USD',
    symbol: '$'
  },
  {
    name: 'Norway',
    currency: 'NOK',
    symbol: 'kr'
  },
  {
    name: 'Oman',
    currency: 'OMR',
    symbol: '﷼'
  },
  {
    name: 'Pakistan',
    currency: 'PKR',
    symbol: '₨'
  },
  {
    name: 'Palau',
    currency: 'USD',
    symbol: '$'
  },
  {
    name: 'Palestinian Territory',
    currency: 'ILS',
    symbol: '₪'
  },
  {
    name: 'Panama',
    currency: 'PAB',
    symbol: 'B/.'
  },
  {
    name: 'Papua New Guinea',
    currency: 'PGK',
    symbol: 'K'
  },
  {
    name: 'Paraguay',
    currency: 'PYG',
    symbol: 'Gs'
  },
  {
    name: 'Peru',
    currency: 'PEN',
    symbol: 'S/.'
  },
  {
    name: 'Philippines',
    currency: 'PHP',
    symbol: '₱'
  },
  {
    name: 'Pitcairn',
    currency: 'NZD',
    symbol: '$'
  },
  {
    name: 'Poland',
    currency: 'PLN',
    symbol: 'zł'
  },
  {
    name: 'Portugal',
    currency: 'EUR',
    symbol: '€'
  },
  {
    name: 'Puerto Rico',
    currency: 'USD',
    symbol: '$'
  },
  {
    name: 'Qatar',
    currency: 'QAR',
    symbol: '﷼'
  },
  {
    name: 'Romania',
    currency: 'RON',
    symbol: 'lei'
  },
  {
    name: 'Russian',
    currency: 'RUB',
    symbol: '₽'
  },
  {
    name: 'Rwanda',
    currency: 'RWF',
    symbol: 'R₣'
  },
  {
    name: 'Saint Kitts and Nevis',
    currency: 'XCD',
    symbol: '$'
  },
  {
    name: 'Samoa',
    currency: 'WST',
    symbol: 'WS$'
  },
  {
    name: 'San Marino',
    currency: 'EUR',
    symbol: '€'
  },
  {
    name: 'Sao Tome and Principe',
    currency: 'STD',
    symbol: 'Db'
  },
  {
    name: 'Saudi Arabia',
    currency: 'SAR',
    symbol: '﷼'
  },
  {
    name: 'Senegal',
    currency: 'XOF',
    symbol: 'CFA'
  },
  {
    name: 'Serbia',
    currency: 'RSD',
    symbol: 'Дин.'
  },
  {
    name: 'Seychelles',
    currency: 'SCR',
    symbol: '₨'
  },
  {
    name: 'Sierra Leone',
    currency: 'SLL',
    symbol: 'Le'
  },
  {
    name: 'Singapore',
    currency: 'SGD',
    symbol: '$'
  },
  {
    name: 'Sint Maarten',
    currency: 'ANG',
    symbol: 'ƒ'
  },
  {
    name: 'Slovakia',
    currency: 'EUR',
    symbol: '€'
  },
  {
    name: 'Slovenia',
    currency: 'EUR',
    symbol: '€'
  },
  {
    name: 'Solomon Islands',
    currency: 'SBD',
    symbol: '$'
  },
  {
    name: 'Somalia',
    currency: 'SOS',
    symbol: 'S'
  },
  {
    name: 'South Africa',
    currency: 'ZAR',
    symbol: 'R'
  },
  {
    name: 'South Sudan',
    currency: '',
    symbol: ''
  },
  {
    name: 'Spain',
    currency: 'EUR',
    symbol: '€'
  },
  {
    name: 'Sri Lanka',
    currency: 'LKR',
    symbol: '₨'
  },
  {
    name: 'Sudan',
    currency: 'SDG',
    symbol: 'ج.س.'
  },
  {
    name: 'Suriname',
    currency: 'SRD',
    symbol: '$'
  },
  {
    name: 'Swaziland',
    currency: 'SZL',
    symbol: 'E'
  },
  {
    name: 'Sweden',
    currency: 'SEK',
    symbol: 'kr'
  },
  {
    name: 'Switzerland',
    currency: 'CHF',
    symbol: 'CHF'
  },
  {
    name: 'Syria',
    currency: 'SYP',
    symbol: '£'
  },
  {
    name: 'Taiwan, Province of China',
    currency: 'TWD',
    symbol: 'NT$'
  },
  {
    name: 'Tajikistan',
    currency: 'TJS',
    symbol: 'SM'
  },
  {
    name: 'Tanzania',
    currency: 'TZS',
    symbol: 'TSh'
  },
  {
    name: 'Thailand',
    currency: 'THB',
    symbol: ''
  },
  {
    name: 'Togo',
    currency: 'XOF',
    symbol: 'CFA'
  },
  {
    name: 'Tokelau',
    currency: 'NZD',
    symbol: '$'
  },
  {
    name: 'Tonga',
    currency: 'TOP',
    symbol: 'T$'
  },
  {
    name: 'Trinidad and Tobago',
    currency: 'TTD',
    symbol: 'TT$'
  },
  {
    name: 'Tunisia',
    currency: 'TND',
    symbol: 'د.ت'
  },
  {
    name: 'Turkey',
    currency: 'TRY',
    symbol: '₺'
  },
  {
    name: 'Turkmenistan',
    currency: 'TMT',
    symbol: 'T'
  },
  {
    name: 'Turks and Caicos Islands',
    currency: 'USD',
    symbol: '$'
  },
  {
    name: 'Tuvalu',
    currency: 'AUD',
    symbol: '$'
  },
  {
    name: 'Uganda',
    currency: 'UGX',
    symbol: 'USh'
  },
  {
    name: 'Ukraine',
    currency: 'UAH',
    symbol: '₴'
  },
  {
    name: 'United Arab Emirates',
    currency: 'AED',
    symbol: 'د.إ'
  },
  {
    name: 'United Kingdom',
    currency: 'GBP',
    symbol: '£'
  },
  {
    name: 'United States',
    currency: 'USD',
    symbol: '$'
  },
  {
    name: 'Uruguay',
    currency: 'UYU',
    symbol: '$U'
  },
  {
    name: 'Uzbekistan',
    currency: 'UZS',
    symbol: 'лв'
  },
  {
    name: 'Vanuatu',
    currency: 'VUV',
    symbol: 'VT'
  },
  {
    name: 'Venezuela, Bolivarian Republic of',
    currency: 'VEF',
    symbol: 'Bs'
  },
  {
    name: 'Viet Nam',
    currency: 'VND',
    symbol: '₫'
  },
  {
    name: 'Virgin Islands',
    currency: 'USD',
    symbol: '$'
  },
  {
    name: 'Yemen',
    currency: 'YER',
    symbol: '﷼'
  },
  {
    name: 'Zambia',
    currency: 'ZMK',
    symbol: ''
  },
  {
    name: 'Zimbabwe',
    currency: 'ZWL',
    symbol: ''
  }
];

export default currencies;
