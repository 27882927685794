import { Decimal } from 'decimal.js';
import get from 'lodash/get';
import includes from 'lodash/includes';
import moment from 'utils/moment';
import { format, zonedTimeToUtc } from 'date-fns-tz';
import { endOfDay, endOfMonth, startOfDay, startOfMonth } from 'date-fns';
import getConfig from 'utils/getConfig';
// import moment from 'moment-timezone';

const PRICE_FORMATS = ['amount', 'price', 'dollar'];
const PERCENTAGE_FORMATS = ['percentage', 'percent'];

export function dollars(amount, readable = true) {
  let num = Number(amount);

  if (typeof num === 'number') {
    const isNegative = num < 0;
    num = new Decimal(Math.abs(num)).toDP(2, Decimal.ROUND_HALF_UP).toNumber();
    num = num.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
    if (readable) {
      return isNegative ? `-$${num}` : `$${num}`;
    }
    return Number(num);
  }

  return '';
}

export function round(value) {
  return new Decimal(value || 0).toDP(2, Decimal.ROUND_HALF_UP).toNumber();
}

export function formatNumber(amount, type = 'float') {
  if (typeof amount === 'undefined' || amount === null) {
    return '';
  }

  if (includes(PERCENTAGE_FORMATS, type)) {
    return `${amount}%`;
  }

  if (type === 'float') {
    return Number(amount || 0).toFixed(2);
  }

  if (type === 'integer') {
    return parseInt(Number(amount)).toLocaleString();
  }

  if (includes(PRICE_FORMATS, type)) return dollars(amount);

  return '';
}

export function capitalize(str, defaultValue = '') {
  return typeof str === 'string'
    ? str.charAt(0).toUpperCase() + str.slice(1)
    : defaultValue;
}

export function pascalCase(str) {
  return str.replace(
    /(\w)(\w*)/g,
    (g0, g1, g2) => g1.toUpperCase() + g2.toLowerCase()
  );
  // return str.replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => word.toUpperCase()).replace(/\s+/g, '');
}

export function formatPrice(amount) {
  return typeof amount === 'undefined' || amount === null
    ? ''
    : dollars(amount);
}

export function formatDate(date, format = 'DD-MM-YYYY') {
  return date ? moment(date).format(format) : '';
}

export function formatDateTime(date, format = 'HH:mm DD-MM-YYYY') {
  return date ? moment(date).format(format) : '';
}

export function formatDateApi(date, type) {
  if (!date || !type) return undefined;
  const config = getConfig();
  const timezone = get(config('TIMEZONE'), 'value');
  if (type === 'startOfDay') {
    return format(
      zonedTimeToUtc(startOfDay(new Date(date)), timezone),
      "yyyy-MM-dd'T'HH:mm:ss.SSSxxx",
      { timeZone: timezone }
    );
  }

  if (type === 'endOfDay') {
    return format(
      zonedTimeToUtc(endOfDay(new Date(date)), timezone),
      "yyyy-MM-dd'T'HH:mm:ss.SSSxxx",
      { timeZone: timezone }
    );
  }

  if (type === 'startOfMonth') {
    return format(
      zonedTimeToUtc(startOfMonth(new Date(date)), timezone),
      "yyyy-MM-dd'T'HH:mm:ss.SSSxxx",
      { timeZone: timezone }
    );
  }

  if (type === 'endOfMonth') {
    return format(
      zonedTimeToUtc(endOfMonth(new Date(date)), timezone),
      "yyyy-MM-dd'T'HH:mm:ss.SSSxxx",
      { timeZone: timezone }
    );
  }

  return undefined;
}

export function formatDateForApi(date, format = 'YYYY-MM-DD HH:mm:ss') {
  return moment.api(date, format);
}

export function slugify(string) {
  const a =
    'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;';
  const b =
    'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------';
  const p = new RegExp(a.split('').join('|'), 'g');

  return string
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/[^\w\-]+/g, '') // Remove all non-word characters
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
}

export function stripTags(str) {
  let elem = document.createElement('div');
  elem.innerHTML = str;
  return elem.innerText;
  // return str.replace(/(<([^>]+)>)/ig, '');
}

export function truncate(str, length) {
  return length > str.length ? str : `${str.substr(0, length)}...`;
}
