import { useQuery } from '@apollo/react-hooks';
import apollo from 'core/apollo';
import query from 'queries/category/categories.graphql';

export default function useCategories() {
  return useQuery(query, getCategoriesQueryOptions());
}

export function fetchCategories() {
  return apollo.query(getCategoriesQueryOptions());
}

export function getCategoriesQueryOptions() {
  return {
    query,
    fetchPolicy: 'cache-first'
  };
}
