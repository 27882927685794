import React from 'react';
import SelectInput from './SelectInput';
import currencies from '../../utils/currencies';

export default function CurrencyInput({
  placeholder,
  onChange,
  value,
  ...props
}) {
  return (
    <SelectInput
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      filterOption={filterOption}
      showSearch
      {...props}
    >
      {currencies.map(currency => (
        <SelectInput.Option
          name={currency.name}
          currency={currency.currency}
          value={currency.name}
          key={currency.name}
        >
          {currency.name} ({currency.currency})
        </SelectInput.Option>
      ))}
    </SelectInput>
  );
}

CurrencyInput.defaultProps = {
  placeholder: 'Select currency...'
};

function filterOption(input, option) {
  let { name, currency } = option.props;
  if (name && currency) {
    return (
      name.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
      String(currency).indexOf(input.toLowerCase()) >= 0
    );
  }
  return false;
}
