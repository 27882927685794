import React from 'react';
import get from 'lodash/get';
import { useBuyers } from 'newapi/buyer/hooks';
import SelectInput from './SelectInput';

export default function BuyerInput(props) {
  let { disabled, placeholder, value, ...rest } = props;
  let { data, loading } = useBuyers();
  let options = get(data, 'buyers', []);

  return (
    <SelectInput
      disabled={disabled || loading}
      loading={loading}
      placeholder={loading ? 'Loading...' : placeholder}
      value={loading ? undefined : value}
      {...rest}
    >
      {options.map(buyer => (
        <SelectInput.Option value={buyer.id}>{buyer.name}</SelectInput.Option>
      ))}
    </SelectInput>
  );
}

BuyerInput.defaultProps = {
  allowClear: true
};
