import groupBy from 'lodash/groupBy';
import { defineAbility } from '@casl/ability';
import { useCurrentUser } from './user';

export default function useAbilities() {
  const user = useCurrentUser();
  const permissionsGroupBy = groupBy(user?.permissions || [], 'action');

  return defineAbility((can, cannot) => {
    Object.entries(permissionsGroupBy).map(permission => {
      can(
        permission[0],
        permission[1].map(permission => permission.subject)
      );
    });
  });
}
