import React from 'react';
import { Input } from 'antd';

export default function TextInput(props) {
  let { onChange, ...rest } = props;

  let handleChange = event => {
    if (typeof onChange === 'function') {
      onChange(event.target.value);
    }
  };

  return <Input onChange={handleChange} {...rest} />;
}
