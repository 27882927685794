import { useQuery } from '@apollo/react-hooks';
import apollo from 'core/apollo';
import query from 'queries/tax/taxes.graphql';

export default function useTaxes() {
  return useQuery(query, getTaxesQueryOptions());
}

export function fetchTaxes() {
  return apollo.query(getTaxesQueryOptions());
}

export function getTaxesQueryOptions() {
  return {
    query,
    fetchPolicy: 'cache-first'
  };
}
