import React, { useLayoutEffect } from 'react';
import { Route } from 'react-router-dom';
import { useCurrentUser } from 'hooks/user';
import auth from 'core/auth';
import router from 'core/router';

export default function ProtectedRoute({ component, path, ...props }) {
  const user = useCurrentUser();
  const initial = async () => {
    const isAuthenticated = await auth.isAuthenticated();
    if (!isAuthenticated && !user) {
      window.localStorage.setItem('redirectTo', window.location.href);
      router.go('/login', true);
    }

    if (!isAuthenticated && user) {
      await auth.refreshToken();
    }
  };

  useLayoutEffect(() => {
    initial();
  });

  if (user) {
    return <Route component={component} path={path} {...props} />;
  }

  return null;
}
