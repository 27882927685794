import { useEffect, useState } from 'react';

export default function useIsOnline(fallbackValue = true) {
  const [isOnline, setIsOnline] = useState(() => {
    if (typeof window !== 'undefined' && window.navigator) {
      return window.navigator.onLine;
    }
    return fallbackValue;
  });

  useEffect(() => {
    function setOnline() {
      setIsOnline(true);
    }

    function setOffline() {
      setIsOnline(false);
    }

    window.addEventListener('online', setOnline);
    window.addEventListener('offline', setOffline);

    return () => {
      window.removeEventListener('online', setOnline);
      window.removeEventListener('offline', setOffline);
    };
  }, []);

  return isOnline;
}
