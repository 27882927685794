import React, { useCallback } from 'react';
import get from 'lodash/get';
import gql from 'graphql-tag';
import { useApolloClient } from '@apollo/react-hooks';
import SelectInput from './AsyncSearchSelectInput';

export default function ProductSearchInput(props) {
  const { mode, value, ...rest } = props;

  const apollo = useApolloClient();

  const searchByKeyword = useCallback(
    async keyword => {
      const result = await apollo.query({
        query: QUERY,
        variables: {
          limit: 10,
          where: {
            _or: [
              {
                name: {
                  _ilike: `%${keyword}%`
                }
              },
              {
                sku: {
                  _ilike: `%${keyword}%`
                }
              }
            ]
          }
        }
      });

      return get(result, 'data.result', []).map(product => ({
        label: product.name,
        description: `SKU: ${product.sku}`,
        value: product.id
      }));
    },
    [apollo]
  );

  const searchByValue = useCallback(
    async values => {
      const result = await apollo.query({
        query: QUERY,
        variables: {
          where: {
            id: {
              _in: values
            }
          }
        }
      });

      return get(result, 'data.result', []).map(product => ({
        label: product.name,
        description: `SKU: ${product.sku}`,
        value: product.id
      }));
    },
    [apollo]
  );

  return (
    <SelectInput
      mode={mode}
      searchByValue={searchByValue}
      searchByKeyword={searchByKeyword}
      value={value}
      {...rest}
    />
  );
}

ProductSearchInput.defaultProps = {
  allowClear: true
};

const QUERY = gql`
  query($limit: Int, $where: product_view_bool_exp) {
    result: product_view(limit: $limit, where: $where) {
      id
      name
      sku
    }
  }
`;
