import { useState } from 'react';

export default function useSteps() {
  const [step, setStep] = useState(0);
  const nextStep = () => setStep(step + 1);
  const prevStep = () => setStep(step - 1);
  const resetStep = () => setStep(0);

  return [step, nextStep, prevStep, resetStep, setStep];
}
