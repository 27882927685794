import { validators } from 'components/form';

export default function validate(data) {
  const errors = {
    first_name: validators.required('First Name', data.first_name),
    last_name: validators.required('Last Name', data.last_name),
    email: validators.isEmail('Email', data.email),
    company_name: validators.required('Company Name', data.company_name),
    company_timezone: validators.required('Timezone', data.company_timezone),
    company_currency: validators.required('Currency', data.company_currency),
    company_address: validators.fullAddress(
      'Company Address',
      data.company_address
    ),
    store_name: validators.required('Company Address', data.store_name),
    store_address: validators.fullAddress('Store Address', data.store_address)
  };

  const filterInvalidSubfields = item => item.code;

  errors.step1 = errors.first_name || errors.last_name || errors.email;
  errors.step2 =
    errors.company_name ||
    errors.company_timezone ||
    errors.company_currency ||
    errors.company_address;
  errors.step3 = errors.store_name || errors.store_address;

  return errors;
}
