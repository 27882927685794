import React from 'react';
import { Result } from 'antd';
import useAbilities from 'hooks/useAbilities';
import { useCurrentUser } from 'hooks/user';
import Page from 'ui/Page';
import Button from 'ui/Button';

export default function Can({
  action,
  subject,
  component: Component,
  hasTitle
}) {
  const user = useCurrentUser();
  const isAdmin = user?.is_admin;
  const abilities = useAbilities();

  if (abilities.can(action, subject) || isAdmin) {
    return <Component />;
  }

  return (
    <Page>
      {hasTitle && <Page.Header backTo title="Access Denied" />}
      <Page.Content>
        <Result
          status="403"
          title="Insufficient Permission to Access Page"
          subTitle="Sorry, you are not authorized to access this page."
          extra={
            <Button icon="home" to="/" type="primary">
              Back Home
            </Button>
          }
        />
      </Page.Content>
    </Page>
  );
}

Can.defaultProps = {
  hasTitle: true
};
