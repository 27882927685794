import { useCallback, useMemo } from 'react';
import get from 'lodash/get';
import toUpper from 'lodash/toUpper';
import { useCurrentUser } from './user';

export default function useGetConfig() {
  const user = useCurrentUser();
  const company = get(user, 'company');
  const settings = getCompanySettings(company);

  const configs = useMemo(
    () => ({
      ...settings
    }),
    [settings]
  );

  return useCallback(key => configs[key], [configs]);
}

function getCompanySettings(company) {
  const settings = get(company, 'settings') || [];
  if (!company || !settings.length) return [];

  return settings.reduce((prev, setting) => {
    const newSetting = {
      [toUpper(get(setting, 'setting_key'))]: {
        value: JSON.parse(get(setting, 'value')),
        key: toUpper(get(setting, 'setting_key')),
        backend_key: get(setting, 'setting_key'),
        type: get(setting, 'type')
      }
    };
    return { ...prev, ...newSetting };
  }, {});
}
