import { useQuery } from '@apollo/react-hooks';
import apollo from 'core/apollo';
import query from 'queries/attribute/attributes.graphql';

export default function useScopeAttributes(scope = 'product') {
  return useQuery(query, getQueryOptions(scope));
}

export function fetchScopeAttributes(scope) {
  return apollo.query(getQueryOptions(scope));
}

export function getQueryOptions(scope) {
  return {
    query,
    fetchPolicy: 'cache-first',
    variables: {
      where: {
        attribute_set: {
          scope: {
            _eq: scope
          }
        }
      }
    }
  };
}
