import { createGlobalStyle } from 'styled-components';
import { breakpoints, colors } from 'config/antd';

export default createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700,900');

  :root {
    --primary-color: ${colors['primary-color']};
    --success-color: ${colors['success-color']};
    --link-color: ${colors['link-color']};
    --error-color: ${colors['error-color']};
    --warning-color: ${colors['warning-color']};
    --title-color: rgba(0, 0, 0, 0.85);
  }

  * {
    box-sizing: border-box;
  }

  body {
    font-family: 'Roboto', sans-serif;
    padding: 0;
    margin: 0;
    transition: background-color 1s ease;
  }

  body.disable-scroll {
    overflow-y: hidden;
  }

  hr {
    background-color: #ddd;
    border: none !important;
    height: 1px !important;
    margin: 1.2em 0;
  }

  .pointer {
    cursor: pointer;
  }

  .pointer * {
    cursor: pointer !important;
  }

  .custom-link {
    color: var(--primary-color);
  }

  .color-muted {
    color: #999;
  }

  .color-success {
    color: var(--success-color);
  }

  .color-error {
    color: var(--error-color);
  }

  .ant-page-header-title-view-title {
    font-weight: normal;
  }

  .ant-select {
    min-width: 100px;
  }

  @media (min-width: 768px) {
    .ant-modal {
      max-width: 90vw;
    }
  }

  .ant-modal-body {
    max-height: 80vh;
    overflow-y: auto;
  }

  .ant-modal-confirm-body-wrapper {
    text-align: center;

    .ant-modal-confirm-body > .anticon {
      color: #ccc;
      display: block;
      float: none;
      font-size: 64px;
      margin: 0 auto 32px;
    }

    .ant-modal-confirm-content {
      margin-left: 0 !important;
    }

    .ant-modal-confirm-btns {
      float: none !important;
      margin: 24px auto 0;
    }
  }

  .ant-calendar-picker-input {
    text-align: left !important;
  }

  @media (max-width: ${breakpoints.sm - 1}px) {
    .ant-calendar-picker-container .ant-calendar-range {
      width: 250px;
    }
    .ant-calendar-picker-container .ant-calendar-range .ant-calendar-range-part {
      float: none;
      width: 100%;
    }
    .ant-calendar-picker-container .ant-calendar-range .ant-calendar-body {
      padding: 8px 0;
    }
    .ant-calendar-picker-container .ant-calendar-range .ant-calendar-input-wrap {
      display: none;
    }
  }

  .auth0-lock-overlay {
    background: #fff !important;
  }

  .auth0-lock-widget {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1) !important;
  }

  .text-center {
    text-align: center;
  }

  .text-left {
    text-align: left;
  }

  .text-right {
    text-align: right;
  }

  .no-padding {
    padding: 0 !important;
  }

  .no-margin {
    margin: 0 !important;
  }

  .bold-text {
    font-weight: bold;
  }

  .font-weight-500 {
    font-weight: 500;
  }

  .font-size-16 {
    font-size: 16px;
  }

  .width1-4 {
    width: 25%;
  }

  .width2-4 {
    width: 50%;
  }

  .width3-4 {
    width: 75%
  }

  .width4-4 {
    width: 100%
  }

  @media (max-width: 480px) {
    .ant-steps-horizontal.ant-steps-label-horizontal {
      display: flex !important;
      justify-content: space-between;

      .ant-steps-item {
        flex: none;

        .ant-steps-item-container {
          .ant-steps-item-tail {
            display: none !important;
          }

          .ant-steps-item-icon {
            display: block;
            float: none;
            margin: 0 auto;
          }

          .ant-steps-item-content {
            min-height: auto;

            .ant-steps-item-title {
              padding-right: 0;
            }

            &.ant-steps-item-finish,
            &.ant-steps-item-process {
              .ant-steps-item-title {
                color: ${colors['primary-color']} !important;
              }
            }
          }
        }
      }
    }
  }
`;
