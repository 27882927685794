import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Button as AntButton } from 'antd';
import useDevice from 'hooks/useDevice';

export default function Button(props) {
  let {
    children,
    onClick,
    replace,
    hideTextInPhone,
    to,
    ...buttonProps
  } = props;

  const history = useHistory();
  let device = useDevice();

  const handleClick = useCallback(
    event => {
      if (typeof to === 'string') {
        history.push(to);
      }

      if (typeof onClick === 'function') {
        onClick(event);
      }
    },
    [history, onClick, to]
  );

  return (
    <AntButton htmlType="button" {...buttonProps} onClick={handleClick}>
      {device.phone && hideTextInPhone ? null : children}
    </AntButton>
  );
}

Button.Group = AntButton.Group;
