import moment from './moment';
import get from 'lodash/get';
import mapValues from 'lodash/mapValues';
import groupBy from 'lodash/groupBy';
import omit from 'lodash/omit';
import lowerCase from 'lodash/lowerCase';
import flattenDeep from 'lodash/flattenDeep';
import { capitalize } from './format';

export function env(key, defaultValue = null) {
  return process.env[key] || defaultValue;
}

export function randomId(prefix = '') {
  return `${prefix}_${Math.random()
    .toString(36)
    .substr(2, 9)}`;
}

export function getName(data, defaultValue = '') {
  return (
    `${get(data, 'first_name') || ''} ${get(data, 'last_name') || ''}`.trim() ||
    defaultValue
  );
}

export function getPersonName(data, defaultValue = '') {
  if (data) {
    return data.is_company ? data.company_name : getName(data, defaultValue);
  }
  return defaultValue;
}

export function diff(start, end, unit = 'days') {
  if (start && end) {
    const _from = moment(start);
    const _to = moment(end);
    return _to.diff(_from, unit);
  }
  return 0;
}

export function normalizeDataToArrayString(data) {
  const dataLen = data.length;
  let result = '{';
  data.map((id, index) => {
    if (dataLen === index + 1) {
      return (result = result + id);
    } else {
      return (result = result + id + ',');
    }
  });
  return (result = result + '}');
}

export function getTimezone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone || 'Australia/Sydney';
}

export function arrayChecker(arr, callback) {
  return arr.every(value => callback(value));
}

export function groupByKeys(seq, keys) {
  if (!keys.length) return seq;
  const first = keys[0];
  const rest = keys.slice(1);
  return mapValues(groupBy(seq, first), function(value) {
    return groupByKeys(value, rest);
  });
}

/**
 * Returns TRUE if the first specified array contains all elements
 * from the second one. FALSE otherwise.
 *
 * @param {array} superset
 * @param {array} subset
 *
 * @returns {boolean}
 */
export function arrayContainsArray(superset, subset) {
  return subset.every(function(value) {
    return superset.indexOf(value) >= 0;
  });
}

export function insert(arr, index, newItem) {
  return [
    // part of the array before the specified index
    ...arr.slice(0, index),
    // inserted item
    newItem,
    // part of the array after the specified index
    ...arr.slice(index)
  ];
}

export function getDemandForecastVariables(data, prefix) {
  const keys = Object.keys(omit(data, ['__typename']));
  const groupedBy = groupBy(keys, a => a.split('_')[0]);
  const prefixKey = prefix?.split('sales_')[1];
  return Object.keys(groupedBy).map(groupName => {
    const group = groupedBy[groupName];
    return (group || []).map(key => ({
      label: prefix
        ? `${capitalize(lowerCase(key))} - ${capitalize(lowerCase(prefixKey))}`
        : capitalize(lowerCase(key)),
      type: 'variable',
      value: prefix ? `${prefix}__${key}` : key,
      localId: randomId(key),
      group: groupName,
      children: groupName.includes('aggregated')
        ? flattenDeep(getDemandForecastVariables(get(data, key)[0], key))
        : undefined
    }));
  });
}

export function findDeep(obj, key, value) {
  if (obj[key] === value) {
    return obj;
  } else {
    var keys = Object.keys(obj); // add this line to iterate over the keys

    for (var i = 0, len = keys.length; i < len; i++) {
      var k = keys[i]; // use this key for iteration, instead of index "i"

      // add "obj[k] &&" to ignore null values
      if (obj[k] && typeof obj[k] == 'object') {
        var found = findDeep(obj[k], key, value);
        if (found) {
          // If the object was found in the recursive call, bubble it up.
          return found;
        }
      }
    }
  }
}

export function getFormulation(statement) {
  return (statement || [])
    .reduce((prev, item) => {
      const variableBeforeCondition = prev[prev.length - 2];

      if (item.type === 'number' && (item.value || []).length > 1) {
        if (prev.slice(0, -2).length === 0) {
          return [
            {
              type: 'includes',
              value: `${item.value === '!=' ? '!' : ''}includes(${
                variableBeforeCondition.value
              }, [${item.value.map(v => v).join(',')}])`
            }
          ];
        }
        return [
          prev.slice(0, -2),
          {
            type: 'includes',
            value: `${item.value === '!=' ? '!' : ''}includes(${
              variableBeforeCondition.value
            }, [${item.value.map(v => v).join(',')}])`
          }
        ];
      }

      return [...prev, item];
    }, [])
    .map(i => i.value);
}

export function getStatementOutput(statement) {
  return statement.reduce((text, item) => `${text} ${item}`, '');
}

export function getFormula(statements, type, roundingState) {
  if (type === 'if_else') {
    const ifStatement = getStatementOutput(statements[0]);
    const elseStatement = getStatementOutput(statements[1]);
    const thenStatement = getStatementOutput(statements[2]);

    return getRounding(
      `${ifStatement} ? ${thenStatement} : ${elseStatement}`,
      roundingState
    );
  }

  return getRounding(getStatementOutput(statements[0]), roundingState);
}

export function getRounding(formula, roundingState) {
  if (roundingState === 'rounding-up') {
    return `roundUpPackSize(${formula}, product_pack_size)`;
  }
  if (roundingState === 'rounding-down') {
    return `roundDownPackSize(${formula}, product_pack_size)`;
  }

  return formula;
}

export function getAddress(address, defaultValue = '') {
  if (address) {
    const streetAddress = getStreetAddress(address.address_1) || '';
    const address2 = get(address, 'address_2');
    const city = get(address, 'city') || '';
    const state = get(address, 'state') || '';
    const country = get(address, 'country_code') || '';
    const postalCode = get(address, 'postal_code') || '';

    if (address2) {
      return `${address2}, ${streetAddress}, ${city}, ${state} ${postalCode}, ${country}`;
    }

    return `${streetAddress}, ${city}, ${state} ${postalCode}, ${country}`;
  }
  return defaultValue;
}

export function getStreetAddress(address) {
  if (address) {
    const streetAddress = address.split(',');
    return streetAddress && streetAddress[0];
  }
  return '';
}
