import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

const router = {
  go(to, replace = false) {
    if (replace) {
      history.replace(to);
    } else {
      history.push(to);
    }
  }
};

export { history };

export default router;
