import React, { useEffect } from 'react';
import DashboardTemplate from 'components/layout';
import Page from 'ui/Page';

export default function ErrorFallback({ error }) {
  // Handle failed lazy loading of a JS/CSS chunk.
  useEffect(() => {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    const cssChunkFailedMessage = /Loading CSS chunk [\d]+ failed/;
    if (
      chunkFailedMessage.test(error?.message) ||
      cssChunkFailedMessage.test(error?.message)
    ) {
      if (!getWithExpiry('chunk_failed')) {
        setWithExpiry('chunk_failed', 'true', 10000);
        window.location.reload();
      }
    }
  }, [error]);

  return (
    <DashboardTemplate>
      <Page>
        <Page.Header title="Error" />
        <Page.Content>
          <p>Something went wrong</p>
          <pre>{error?.message}</pre>
        </Page.Content>
      </Page>
    </DashboardTemplate>
  );
}

function setWithExpiry(key, value, ttl) {
  const item = {
    value: value,
    expiry: new Date().getTime() + ttl
  };
  localStorage.setItem(key, JSON.stringify(item));
}

function getWithExpiry(key) {
  const itemString = window.localStorage.getItem(key);
  if (!itemString) return null;

  const item = JSON.parse(itemString);
  const isExpired = new Date().getTime() > item.expiry;

  if (isExpired) {
    localStorage.removeItem(key);
    return null;
  }

  return item.value;
}
