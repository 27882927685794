import { message } from 'antd';
import { Auth0Client } from '@auth0/auth0-spa-js';
import get from 'lodash/get';
import apollo from 'core/apollo';
import { setGlobalData } from 'core/store';
import { env } from 'utils/common';
import me from 'queries/user/me.graphql';

const clientId = env('REACT_APP_AUTH0_CLIENT_ID');
const domain = env('REACT_APP_AUTH0_DOMAIN');
const callbackUri = `${window.location.origin}/callback`;

const AUTH_CLIENT = new Auth0Client({
  domain: domain,
  client_id: clientId,
  redirect_uri: callbackUri,
  scope: 'openid email',
  useRefreshTokens: true,
  cacheLocation: 'localstorage'
});

const AUTH = {
  isAuthenticated() {
    return AUTH_CLIENT.isAuthenticated();
  },

  async authenticate() {
    try {
      await AUTH_CLIENT.handleRedirectCallback();
      const accessToken = await AUTH_CLIENT.getTokenSilently();
      const idToken = await AUTH_CLIENT.getIdTokenClaims();

      localStorage.setItem('access_token', accessToken);
      localStorage.setItem('id_token', idToken.__raw);

      let redirectTo = window.localStorage.getItem('redirectTo');

      if (redirectTo) {
        window.localStorage.removeItem('redirectTo');
        window.location.href = redirectTo;
      } else {
        window.location.href = '/';
      }
    } catch (error) {
      window.location.href = '/login';
    }
  },

  async init() {
    const isAuthenticated = await this.isAuthenticated();
    if (isAuthenticated) {
      try {
        const response = await apollo.query({
          query: me
        });

        if (!response.data || !response.data.me) {
          this.logout();
          // window.location.href = '/login';
        }

        const user = get(response, 'data.me');

        if (user && user.id) {
          setGlobalData('user', user);
        }

        return user;
      } catch (error) {
        this.logout();
      }
    } else {
      //  webAuth.authorize();
    }
  },

  async login() {
    const isAuthenticated = await this.isAuthenticated();
    if (!isAuthenticated) {
      await AUTH_CLIENT.loginWithRedirect({
        prompt: 'login'
      });
      // webAuth.authorize({ prompt: 'login' });
    }
  },

  async refreshToken() {
    try {
      const accessToken = await AUTH_CLIENT.getTokenSilently();
      const idToken = await AUTH_CLIENT.getIdTokenClaims();
      localStorage.setItem('access_token', accessToken);
      localStorage.setItem('id_token', idToken.__raw);
    } catch (error) {
      message.error(
        'An error occurred while refreshing token. Please login again.'
      );
      this.logout();
    }
  },

  logout() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    AUTH_CLIENT.logout();
    window.location.href = '/login';
  }
};

export default AUTH;
