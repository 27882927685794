import { fetchScopeAttributes } from './attribute/useScopeAttributes';
import { fetchScopeAttributeGroups } from './attribute/useScopeAttributeGroups';
import { fetchStores } from './store/useStores';
import { fetchWarehouses } from './warehouse/useWarehouses';
import { fetchCategories } from './category/useCategories';
import { fetchTaxes } from './tax/useTaxes';
import { fetchOrderStatuses } from './orders/useOrderStatuses';
import { fetchBuyerCategories } from './category/useBuyerCategories';
import { fetchSuppliers } from './suppliers/useSuppliers';
import { fetchJobOrderStatuses } from './job-orders/useJobOrderStatuses';

export default function prefetchGlobalQueries() {
  fetchCategories();
  fetchBuyerCategories();
  fetchStores();
  fetchWarehouses();
  fetchScopeAttributes('product');
  fetchScopeAttributeGroups('product');
  fetchTaxes();
  fetchOrderStatuses();
  fetchSuppliers();
  fetchJobOrderStatuses();
}
