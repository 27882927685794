import get from 'lodash/get';
import clone from 'lodash/clone';
import replace from 'lodash/replace';

export function arrayToObject(arr) {
  return arr
    ? arr.reduce((result, item) => ({ ...result, [item.id]: item }), {})
    : [];
}

export function sortAlphabetically(data, keyToSort = 'name') {
  function sort(input) {
    let output = clone(input);
    output.children =
      input.children && input.children.length > 0 ? sort(input.children) : [];
    output.sort((a, b) =>
      (get(a, keyToSort) || '').localeCompare(get(b, keyToSort) || '')
    );
    return output;
  }

  return sort(data);
}

export function normalizeCategories(data, maxLevel = 6) {
  let output = [];
  let dash = '—';

  function flatten(input, level = 0, prefix = '') {
    input.forEach(item => {
      output.push({
        ...item,
        nameLeveled: `${prefix}${item.name}`,
        searchString: `${replace(item.name, ' - ', '-')}`,
        children: undefined
      });
      if (level < maxLevel && item.children && item.children.length) {
        flatten(item.children, level + 1, `${prefix}${dash} `);
      }
    });
  }

  flatten(sortAlphabetically(data));

  return output;
}

export function normalizeSources({ source, maxLevel = 2 }) {
  let output = [];
  const children = get(source, 'childrens') || [];

  function flatten(input, level = 0) {
    input.forEach(item => {
      output.push({
        // ...item,
        id: item.id,
        name: item.name
      });
      if (level < maxLevel && item.childrens && item.childrens.length) {
        flatten(item.childrens, level + 1);
      }
    });
  }

  flatten(children);

  return output;
}

export function normalizeContactTypes({ data, maxLevel = 3 }) {
  let output = [];
  const dash = '—';

  function flatten(input, level = 0, prefix = '') {
    input.forEach(item => {
      output.push({
        ...item,
        nameLeveled: `${prefix}${item.name}`,
        searchString: `${replace(item.name, ' - ', '-')}`,
        children: undefined
      });
      if (level < maxLevel && item.children && item.children.length) {
        flatten(item.children, level + 1, `${prefix}${dash} `);
      }
    });
  }

  flatten(sortAlphabetically(data));

  return output;
}
