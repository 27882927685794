const mobileMaxWidth = 768;

function getWindowWidth() {
  return window.innerWidth;
}

function getScreenData() {
  const width = getWindowWidth();

  return {
    isMobile: width <= mobileMaxWidth,
    width
  };
}

export { getWindowWidth, getScreenData };
