import React, { useEffect, useMemo, useState } from 'react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { useApolloClient } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { Drawer, Icon, InputNumber, message } from 'antd';
import useTransferSaleStockToHireStock from 'api/products/useTransferSaleStockToHireSotck';
import Button from 'ui/Button';
import Form from 'ui/Form';
import Modal from 'ui/Modal';
import StoreInput from 'components/input/StoreInput';
import TextInput from 'components/input/TextInput';
import AffixButtons from 'components/affix-buttons';
import ProductSearchInput from 'components/input/ProductSearchInput';
import useMiniForm from 'hooks/useMiniForm';
import validateTransferSale from './utils/validator';
import classes from './index.module.css';

export default function TransferSaleStock({
  product_id,
  refetchQueries,
  ...buttonProps
}) {
  const apollo = useApolloClient();
  const [transfer] = useTransferSaleStockToHireStock();
  const doRefetch = !isEmpty(refetchQueries);
  const initialValues = useMemo(
    () => ({
      from_product_id: product_id,
      qty: 0
    }),
    [product_id]
  );
  const [errors, setErrors] = useState({});
  const form = useMiniForm({
    initialValues,
    async onSubmit(values) {
      try {
        const errs = validateTransferSale(values);
        if (!isEmpty(errs)) {
          setErrors(errs);
          return;
        }
        setErrors({});
        const payload = {
          from_product_id: values.from_product_id,
          from_store_id: values.from_store_id,
          to_store_id: values.to_store_id,
          to_product_id: values.to_product_id,
          codes: [...Array(qty)].map((_, i) => get(values, `code_${i}`))
        };
        await transfer({
          awaitRefetchQueries: doRefetch,
          refetchQueries,
          variables: {
            ...payload
          }
        });
        Modal.success({
          title: 'Stock has been transferred successfully.',
          onOk() {
            onClose();
            form.reset();
          }
        });
      } catch (error) {
        Modal.error({
          title: error.message
        });
      }
    }
  });
  const productId = form.get('from_product_id');
  const fromStore = form.get('from_store_id');
  const [loading, setLoading] = useState(false);
  const [soh, setSoh] = useState(0);
  const [visible, setVisible] = useState(false);
  const onOpen = () => setVisible(true);
  const onClose = () => {
    setVisible(false);
  };

  const qty = form.get('qty') || 0;

  const getErrorPropsFor = name => {
    const err = get(errors, name);
    if (err) {
      return {
        validateStatus: 'error',
        help: err
      };
    }
  };

  useEffect(() => {
    form.change('to_store_id', fromStore);
  }, [fromStore]);

  useEffect(() => {
    const fetchStocks = async () => {
      try {
        setLoading(true);
        const response = await apollo.query({
          query: QUERY,
          variables: {
            where: {
              store_id: {
                _eq: fromStore
              },
              product_id: {
                _eq: productId
              }
            }
          }
        });

        setSoh(get(response, 'data.result[0].soh', 0));
      } catch (error) {
        message.error(error.message);
      } finally {
        setLoading(false);
      }
    };

    if (productId && fromStore) {
      fetchStocks();
    }

    if (!productId || !fromStore) {
      setSoh(0);
    }
  }, [productId, fromStore, apollo]);

  return (
    <>
      <Button {...buttonProps} onClick={onOpen}>
        Transfer Sale Stock to Hire Stock
      </Button>

      <Drawer
        title="Transfer Sale Stock to Hire Stock"
        visible={visible}
        onClose={onClose}
        destroyOnClose
        width={600}
      >
        <Form className={classes.form}>
          <Form.Item
            label="Select Product"
            {...getErrorPropsFor('from_product_id')}
          >
            <ProductSearchInput
              placeholder="Search for product"
              {...form.getInputPropsFor('from_product_id')}
            />
          </Form.Item>

          <h3>From</h3>
          <Form.Item
            label="Store/Warehouse"
            {...getErrorPropsFor('from_store_id')}
          >
            <StoreInput
              placeholder="From Store"
              {...form.getInputPropsFor('from_store_id')}
            />
          </Form.Item>

          <Form.Item label="Quantity to Transfer" {...getErrorPropsFor('qty')}>
            <InputNumber
              min={0}
              max={soh > 0 ? soh : 0}
              placeholder="QTY"
              {...form.getInputPropsFor('qty')}
            />
            <br />
            <Icon type="info-circle" /> {loading ? '...' : soh} Available
          </Form.Item>

          <h3>To</h3>
          <Form.Item
            label="Store/Warehouse"
            {...getErrorPropsFor('to_store_id')}
          >
            <StoreInput
              placeholder="To Store"
              {...form.getInputPropsFor('to_store_id')}
            />
          </Form.Item>

          <Form.Item
            label="Select Product"
            {...getErrorPropsFor('to_product_id')}
          >
            <ProductSearchInput
              placeholder="Search for product"
              {...form.getInputPropsFor('to_product_id')}
            />
          </Form.Item>

          <Form.Item
            label="Specify Serial Number"
            {...getErrorPropsFor('codes')}
          >
            {[...Array(qty)].map((_, i) => (
              <div className={classes.serialNumber}>
                <TextInput
                  placeholder="Enter Serial Number"
                  {...form.getInputPropsFor(`code_${i}`)}
                />
                {/* <Button onClick={() => handleRemoveSerialNumber(`code_${i}`)}><Icon type="delete" /></Button> */}
              </div>
            ))}
          </Form.Item>

          <Form.Item>
            <AffixButtons>
              <Button
                disabled={loading}
                loading={form.submitting}
                onClick={form.submit}
                type="primary"
              >
                Transfer to Hire
              </Button>
            </AffixButtons>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
}

const QUERY = gql`
  query($where: product_stock_bool_exp) {
    result: product_stock(where: $where) {
      id
      store_id
      soh
    }
  }
`;
