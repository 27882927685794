import { useQuery } from '@apollo/react-hooks';
import apollo from 'core/apollo';
import query from 'queries/order/order-status.graphql';

export default function useOrderStatuses() {
  return useQuery(query, getOrderStatusesQueryOptions());
}

export function fetchOrderStatuses() {
  return apollo.query(getOrderStatusesQueryOptions());
}

export function getOrderStatusesQueryOptions() {
  return {
    query,
    fetchPolicy: 'cache-first'
  };
}
