import { message, Modal } from 'antd';

let reportError = message.error;

message.error = config => {
  if (typeof config === 'string') {
    return reportError(config.replace('GraphQL error:', '').trim());
  }

  return reportError({
    ...config,
    content: config.content.replace('GraphQL error:', '').trim()
  });
};

Modal.defaultProps.centered = true;
