import React from 'react';
import styled from 'styled-components';
import Page from 'components/page';

const Wrapper = styled('div')`
  color: #ccc;
  font-size: 48px;
  padding: 50px;
  text-align: center;
`;

export default function ComingSoon() {
  return (
    <Page>
      <Page.Content>
        <Wrapper>Coming Soon</Wrapper>
      </Page.Content>
    </Page>
  );
}
