import React from 'react';
import styled, { css } from 'styled-components';
import { Radio } from 'antd';
import { breakpoints } from 'config/antd';

export default function RadioGroupInput(props) {
  let { children, onChange, useBoxStyle, value, ...rest } = props;

  function handleChange(event) {
    if (typeof onChange === 'function') {
      onChange(event.target.value);
    }
  }

  return (
    <StyledGroup
      onChange={handleChange}
      useBoxStyle={useBoxStyle}
      value={value}
      {...rest}
    >
      {children}
    </StyledGroup>
  );
}

RadioGroupInput.Radio = Radio;
RadioGroupInput.Button = Radio.Button;

let boxButtonCss = css`
  .ant-radio-button-wrapper {
    height: auto !important;
  }

  @media (max-width: ${breakpoints.md - 1}px) {
    .ant-radio-button-wrapper {
      .anticon {
        margin: 8px 0;
      }
    }
  }

  @media (min-width: ${breakpoints.md}px) {
    .ant-radio-button-wrapper {
      padding: 32px !important;

      .anticon {
        display: block;
        font-size: 28px;
        margin-bottom: 8px;
      }
    }
  }
`;

let StyledGroup = styled(Radio.Group)`
  ${props => (props.useBoxStyle ? boxButtonCss : null)}
`;
