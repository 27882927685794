import { useEffect, useRef, useState } from 'react';
import { breakpoints } from 'config/antd';

function getScreenData() {
  const width = window.innerWidth;

  return {
    desktop: width >= breakpoints.lg,
    phablet: width < breakpoints.lg,
    phone: width <= breakpoints.sm,
    tablet: width > breakpoints.sm && width < breakpoints.lg
  };
}

export default function useDevice() {
  let [data, setData] = useState(getScreenData);
  let timeoutRef = useRef();

  function resize() {
    if (timeoutRef.current) {
      window.clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = window.setTimeout(() => {
      setData(getScreenData());
    }, 500);
  }

  useEffect(() => {
    window.addEventListener('resize', resize);
    return () => {
      window.removeEventListener('resize', resize);
    };
  }, []);

  return {
    ...data,
    get: values => {
      if (data.phablet && typeof values.phablet !== 'undefined') {
        return values.phablet;
      }
      if (data.phone && typeof values.phone !== 'undefined') {
        return values.phone;
      }
      if (data.tablet && typeof values.tablet !== 'undefined') {
        return values.tablet;
      }
      if (data.desktop && typeof values.desktop !== 'undefined') {
        return values.phone;
      }
      return values.default;
    }
  };
}
