import get from 'lodash/get';
import isUndefined from 'lodash/isUndefined';
import omitBy from 'lodash/omitBy';
import isString from 'lodash/isString';
import { validators } from 'components/form/validation';

export default function validateTransferSale(data) {
  const qty = data.qty || 0;
  const codes = [...Array(qty)]
    .map((_, i) => get(data, `code_${i}`))
    .filter(code => isString(code) && code.length > 0);
  const errors = {
    from_product_id: validators.required('Product', data.from_product_id),
    qty: validators.min('QTY', data.qty, 1),
    from_store_id: validators.required('From Store', data.from_store_id),
    to_store_id: validators.required('To Store', data.to_store_id),
    codes: validators.isEqual(
      qty,
      codes.length,
      'You must fill all serial numbers.'
    )
  };

  return omitBy(errors, isUndefined);
}
